import React from 'react';
import { __ } from '../i18n';

export default class TermsAndPrivacy extends React.Component {
  render() {
    const { entityTypes } = this.props;

    window.location.replace("https://politicaprivacidade.arcoeducacao.com.br/");


    return (
      <div className="terms">
        {/* <h1 style={{ marginBottom: '38px' }}>Termos de Uso e Política de Privacidade</h1>
        <ul>
          {
            (entityTypes.includes('student') || entityTypes.includes('noentity')) &&
            <li><a href="#terms-student">TERMOS E CONDIÇÕES DE USO DA CLASSAPP PARA ALUNOS E RESPONSÁVEIS</a></li>
          }
          {
            entityTypes.includes('staff') &&
            <li><a href="#terms-staff">TERMOS E CONDIÇÕES DE USO DA CLASSAPP PARA FUNCIONÁRIO DA INSTITUIÇÃO DE ENSINO</a></li>
          }
          {
            entityTypes.includes('admin') &&
            <li><a href="#terms-admin">TERMOS E CONDIÇÕES DE USO DA CLASSAPP PARA ADMINISTRADOR DE SISTEMA</a></li>
          }

          {
            (entityTypes.includes('student') || entityTypes.includes('noentity')) &&
            <li><a href="#policy-student">POLÍTICA DE PRIVACIDADE E TRATAMENTO DE DADOS DA CLASSAPP PARA ALUNOS E RESPONSÁVEIS (“POLÍTICA”)</a></li>
          }
          {
            (entityTypes.includes('staff') || entityTypes.includes('admin')) &&
            <li><a href="#policy-staff-admin">POLÍTICA DE PRIVACIDADE E TRATAMENTO DE DADOS DA CLASSAPP PARA ADMINISTRADOR DE SISTEMA E FUNCIONÁRIO DA INSTITUIÇÃO DE ENSINO (“POLÍTICA”)</a></li>
          }
        </ul>

        {
          (entityTypes.includes('student') || entityTypes.includes('noentity')) &&
          <section>
            {!this.props.noHeader && <h2 id="terms-student" className="avoid-header-overlapping" style={{ marginBottom: '38px' }}>TERMOS E CONDIÇÕES DE USO DA CLASSAPP PARA ALUNOS E RESPONSÁVEIS</h2>}

            <p>Atualizada em 19 de julho de 2021.</p>
            <br/><p>Bem-vindo(a) à <strong>ClassApp</strong>!</p>
            <br/>
            <p>A seguir são apresentados os Termos e Condições de Uso (“Termos”) contendo as principais regras que devem ser observadas por <strong>Você </strong>ao acessar e utilizar as funcionalidades disponíveis na área logada do site institucional, disponível no <em>link</em> &lt;www.classapp.com.br&gt; (“Portal ClassApp”), bem como do aplicativo (“Aplicativo ClassApp”), em conjunto denominados de “Nossos Ambientes”, pertencentes a:</p>
            <br/>
            <p><strong>CLASSAPP SISTEMAS LTDA.</strong>, (“<strong>Nós</strong>”)</p>
            <br/>
            <p>RUA GUARARAPES, Nº 00136, , KUHL -VL. - LIMEIRA/SP - CEP: 13480-000</p>
            <br/>
            <p>CNPJ: 21.560.411/0001-01</p>
            <br/>

            <p>Como condição para acesso e uso das funcionalidades existentes em Nossos Ambientes, <strong>Você</strong> declara que fez a leitura completa e atenta das regras deste documento, estando plenamente ciente e de acordo com elas.</p>
            <br/>
            <p>Para acesso aos Nossos Ambientes, poderão ser requeridas determinadas informações sobre <strong>Você</strong>, com o intuito de garantir a segurança e a identificação no uso de Nossos Ambientes. Não se preocupe, a utilização de seus dados está regulada de acordo com nossa Política de Privacidade e Tratamento de Dados, que pode ser acessada por <strong>Você</strong> a qualquer momento.</p>
            <br/>
            <p><b>DEFINIÇÕES</b> </p>
            <br/><p><strong><em>AntiSpam</em>:</strong> Sistema que evita correspondências indesejadas, como publicidade em massa, pelo bloqueio de mensagem ou direcionamento destas para pastas específicas.</p>
            <br/><p><strong>Aplicativo ClassApp:</strong> Aplicativo disponibilizado aos alunos, pais, responsáveis legais, pedagógicos e financeiros, com a finalidade de proporcionar uma alternativa conveniente para que estes interajam com nossos conteúdos e utilizem as funcionalidades e serviços oferecidos, podendo ser baixado por meio do <em>App Store</em> e <em>Google Play</em>.</p>
            <br/><p><strong><em>Backup</em>:</strong> Salvaguarda de informações, realizada por meio da reprodução e/ou espelhamento de uma base de arquivos, com a finalidade de plena capacidade de recuperação em caso de incidente ou necessidade de <em>restore</em>, ou constituição de infraestrutura de acionamento imediato em caso de incidente ou necessidade justificada pelo Usuário.</p>
            <br/><p><strong>Canais de Atendimento:</strong> Meios de contato disponibilizados por <strong>Nós</strong>, para o envio de dúvidas e sugestões relacionadas aos Nossos Ambientes.</p>
            <br/><p><strong>ClassPay:</strong> Funcionalidade disponível no Portal ClassApp que permite o pagamento de despesas avulsas e esporádicas dos alunos por seus pais ou responsáveis legais e/ou financeiros.</p>
            <br/><p><strong>Conta de Acesso:</strong> Credencial de um Usuário que permite acesso à área restrita e às funcionalidades exclusivas existentes em Nossos Ambientes, definida pela combinação de <em>e-mail</em> ou número de celular e senha.</p>
            <br/><p><strong>Instituições de Ensino:</strong> Instituições de ensino clientes, que contratam nossos serviços para utilização por seus funcionários, representantes, terceiros, alunos e responsáveis por alunos.</p>
            <br/><p><strong><em>Layout</em>:</strong> Conjunto compreendido entre aparência, <em>design</em> e fluxos de Nossos Ambientes.</p>
            <br/><p><strong><em>Link</em>:</strong> Terminologia para endereço de <em>internet</em>.</p>
            <br/><p><strong><em>Login</em>:</strong> Processo para acessar um sistema informático restrito feito através da autenticação ou identificação do Usuário, usando credenciais previamente cadastradas no sistema.</p>
            <br/><p><strong>Matrículas e Financeiro:</strong> Quando disponível ao Usuário, possibilita a realização de matrículas, através da celebração de contrato por meio digital pelos responsáveis dos alunos, além do recebimento de cobranças automáticas.</p>
            <br/><p><strong>Portal ClassApp:</strong> Designa a área logada constante em nosso site institucional, disponível através do endereço eletrônico &lt;www.classapp.com.br&gt;.</p>
            <br/><p><strong>Usuários ou Você:</strong> Pessoas físicas que utilizam Nossos Ambientes através de perfis de acessos como “alunos” ou “responsáveis”. São os alunos, pais, representantes legais, pedagógicos e financeiros que desejam experimentar nossos serviços através de uma simulação ou que foram previamente cadastrados em nosso sistema pelas Instituições de Ensino para utilização de nossos serviços e funcionalidades disponíveis.</p>
            <br/><p><strong>FUNCIONALIDADES DISPONÍVEIS EM NOSSOS AMBIENTES</strong></p>
            <br/><p><strong>Restrição de acesso.</strong> As funcionalidades disponíveis em Nossos Ambientes são destinadas a alunos, pais, responsáveis legais, financeiros e pedagógicos, interessados em experimentar nossos serviços através de uma simulação ou previamente cadastrados em nosso sistema pelas Instituições de Ensino.</p>
            <br/><p>Na eventualidade de Nossos Ambientes serem acessados por um menor de idade, os pais ou representantes legais deverão supervisionar e representar as crianças e adolescentes com idade inferior a 16 (dezesseis) anos e assistir os adolescentes em idade superior à 16 (dezesseis) anos e inferior à 18 (dezoito) anos, sempre que aplicável.</p>
            <br/><p><strong>Nós</strong> podemos recusar, não aprovar ou cancelar a criação de seu cadastro caso <strong>Você</strong> se utilize de dados incorretos ou falsos. O mesmo será aplicável durante a utilização de Nossos Ambientes, caso seja verificado seu uso de forma fraudulenta, bem como que tente ou viole estes Termos, ou quaisquer legislações vigentes.</p>
            <br/><p>Não obstante, caso seja verificada por <strong>Nós</strong> a existência de atividade suspeita ou transação que apresente considerável risco de fraude, <strong>Nós</strong> poderemos a qualquer tempo e a nosso exclusivo critério, solicitar informações adicionais a <strong>Você</strong>, sendo que, qualquer erro ou atraso no envio destas informações que gere prejuízo ou dano de qualquer natureza, será de sua inteira responsabilidade.</p>
            <br/><p><strong>Elegibilidade.</strong> <strong>Nós</strong> podemos alterar os critérios de elegibilidade de acesso e utilização de Nossos Ambientes a qualquer momento e ao nosso critério, sem a necessidade de comunicação ou aviso prévio a <strong>Você</strong> ou a qualquer terceiro, sendo de extrema importância que <strong>Você</strong>, ao fazer uso das funcionalidades existentes em Nossos Ambientes, sempre verifique as regras vigentes.</p>
            <br/><p><strong><em>E-mails</em>.</strong> Ao fornecer seus dados para criação de uma Conta de Acesso em Nossos Ambientes, <strong>Você</strong> está ciente que, nos termos do previsto na Política de Privacidade e Tratamento de Dados, poderá receber, no <em>e-mail</em> e/ou telefone informados, mensagens com avisos e conteúdos publicitários enviados por <strong>Nós</strong>. Lembrando que, através dos Canais de Atendimento, a qualquer tempo, <strong>Você</strong> terá sempre a opção de suspender o recebimento destas comunicações, desde que não prejudique o contato imprescindível à resolução da solicitação realizada por <strong>Você</strong> em Nossos Ambientes.</p>
            <br/><p><strong>Apresentação de Nossos Ambientes.</strong> Nossos Ambientes e as funcionalidades neles existentes são apresentadas a <strong>Você</strong> na maneira como estão disponíveis no momento do acesso, mas podem passar por constantes aprimoramentos e atualizações sem a necessidade de prévio aviso.</p>
            <br/><p>Neste caso, <strong>Nós</strong> nos comprometemos a preservar as funcionalidades de Nossos Ambientes com esforços razoáveis, utilizando <em>layout</em> que respeita a usabilidade e navegabilidade, sempre que possível, bem como a exibir as funcionalidades de maneira clara, completa, precisa e suficiente de modo que exista uma correta percepção do que está sendo praticado e proteger, por meio do estado da técnica disponível, os dados coletados pelas funcionalidades disponibilizadas, garantindo seu sigilo.</p>
            <br/><p><strong>ACESSO AOS NOSSOS AMBIENTES</strong></p>
            <br/><p><strong>Simulação em Nossos Ambientes.</strong> Para experimentar as funcionalidades disponíveis em Nossos Ambientes, <strong>Você</strong> poderá realizar a criação de uma Conta de Acesso, onde serão solicitadas informações que deverão ser prestadas unicamente por <strong>Você</strong>.</p>
            <br/><p>Será necessário que <strong>Você</strong> informe: (i) seu endereço de <em>e-mail</em> e código de acesso enviado para sua caixa de entrada; ou (ii) seu número de celular e código de acesso enviado via SMS. Este código de acesso será gerado a cada vez que <strong>Você</strong> precisar acessar Nossos Ambientes e não possuir uma senha cadastrada.</p>
            <br/><p><strong>Utilização efetiva de Nossos Ambientes.</strong> Para utilização das funcionalidades disponíveis em Nossos Ambientes de forma efetiva, <strong>Nós</strong> realizaremos o pré-cadastro de sua Conta de Acesso, mediante o compartilhamento de seus dados coletados pela Instituição de Ensino.</p>
            <br/><p>Para ativação de sua Conta de Acesso, será necessário que <strong>Você</strong> adote os mesmos procedimentos descritos na cláusula 3.1.1. acima.</p>
            <br/><p>Após seu primeiro acesso em Nossos Ambientes, será possível prosseguir com a criação de uma senha de acesso, a fim de garantir maior segurança aos seus dados.</p>
            <br/><p>Posteriormente ao seu acesso, será disponibilizada a <strong>Você</strong> a sua área logada, na qual <strong>Você</strong> poderá incluir uma foto de perfil de sua escolha.</p>
            <br/><p>Além dos dados acima, para prosseguir com a utilização dos serviços de pagamentos disponíveis no Portal ClassApp, será necessário que <strong>Você</strong> insira os dados de seu cartão de crédito, tais como (i) número; (ii) data de vencimento; (iii) nome que consta no cartão e (iv) código de verificação.</p>
            <br/><p><strong>Validação.</strong> As validações dos acessos realizados por <strong>Você</strong> são importantes para que <strong>Nós</strong> possamos manter a autenticidade da sua Conta de Acesso, prevenindo que esta seja utilizada por terceiros mal-intencionados, além de ser uma forma segura que permite a alteração de suas informações, acesso aos dados, utilização das funcionalidades, entre outros atos necessários no âmbito da navegação de Nossos Ambientes.</p>
            <br/><p><strong>USO DE NOSSOS AMBIENTES POR VOCÊ</strong></p>
            <br/><p><strong>Sigilo dos dados do cadastro.</strong> <strong>Você</strong> deverá manter o sigilo dos dados de sua Conta de Acesso em relação a terceiros e utilizá-los de modo individual e intransferível, não disponibilizando, divulgando ou compartilhando sua senha ou qualquer mecanismo de autenticação com quem quer que seja. Caso suspeite que a confidencialidade de sua senha foi violada, <strong>Você</strong> deverá proceder com sua troca ou atualização o mais rápido possível, além de nos comunicar através dos Canais de Atendimento, para que possam ser tomadas as devidas providências, quando aplicável.</p>
            <br/><p><strong>Você</strong> está ciente e reconhece que todos os acessos e operações realizadas em Nossos Ambientes após seu acesso bem-sucedido são interpretados como sendo de sua responsabilidade, de forma incontestável, inclusive aqueles derivados de uso indevido ou de divulgação deste para terceiros.</p>
            <br/><p><strong>Acesso aos Nossos Ambientes.</strong> Para acesso aos Nossos Ambientes, <strong>Você</strong> deve utilizar-se da <em>internet</em> e:</p>
            <br/><p>Efetuar o acesso ao<em> link </em><a href="http://www.classapp.com.br" rel="noopener noreferrer" target="_blank">www.classapp.com.br</a>, para interagir no Portal ClassApp; ou</p>
            <br/><p>Realizar o <em>download</em>, através da <em>App Store </em>ou <em>Google Play</em>, ao seu dispositivo móvel, do Aplicativo ClassApp.</p>
            <br/><p>Não sendo permitido o acesso por nenhum outro meio diverso não autorizado por <strong>Nós</strong>.</p>
            <br/><p><strong>Funcionalidades de Nossos Ambientes.</strong> Após a realização de sua identificação e autenticação através do <em>Login</em>, <strong>Você</strong> poderá utilizar Nossos Ambientes, onde poderá encontrar e ter acesso a diversas funcionalidades e conteúdos informativos criados para <strong>Você</strong>.</p>
            <br/><p><strong>Funcionalidades disponíveis no Aplicativo ClassApp.</strong> As funcionalidades disponíveis no Aplicativo ClassApp têm, por objetivo, permitir que <strong>Você</strong>: (i) envie mensagens aos demais usuários, podendo esta conter áudios, arquivos e demais formatos de mídias; (ii) visualize postagens realizadas pelos demais usuários através da aba “Momentos”; (iii) acesse o calendário e visualize eventos; (iv) pesquise o histórico de conversas realizadas entre <strong>Você</strong> e demais usuários; e (v) remova a Conta de Acesso vinculada à <strong>Você</strong>.</p>
            <br/><p><strong>Funcionalidades disponíveis no Portal ClassApp.</strong> Além das opções descritas no item 4.4.1. acima, <strong>Você</strong> também encontrará disponíveis no Portal ClassApp as seguintes funcionalidades: (i) edição das informações relativas ao perfil do aluno; (ii) gestão das Contas de Acesso de Usuários vinculados ao perfil do aluno, podendo criar e excluir tais Contas, bem como atualizar suas informações; (iii) utilização da ferramenta ClassPay para realização de pagamentos à Instituição de Ensino; e (iv) quando disponível, acesso ao módulo Matrículas e Financeiro, para realização de matrícula e recebimento de cobranças automáticas.</p>
            <br/><p>Para utilização da funcionalidade de compartilhamento de mídias, <strong>Nós</strong> poderemos solicitar acesso ao seu áudio, câmera ou galeria de imagens. Caso não permita o acesso, o serviço não poderá ser executado.</p>
            <br/><p style={{ marginTop: '0' }}><strong>ATENÇÃO!</strong> Eventuais contratos de matrículas e requisições de pagamentos feitas em Nossos Ambientes são de responsabilidade exclusiva da Instituição de Ensino, a qual é a única responsável por estabelecer as condições de venda, realização e pagamento de seus serviços. <strong>Nós</strong> não somos criadores, fornecedores ou tampouco proprietários dos serviços comercializados em Nossos Ambientes.</p>
            <br/><p><strong>Pagamentos.</strong> Para utilização das funcionalidades ClassPay e Matrículas e Financeiro, disponíveis no Portal ClassApp, <strong>Você</strong> poderá optar pela realização de pagamentos em benefício da Instituição de Ensino, através de boletos bancários ou lançamentos em cartão de crédito. As informações relativas ao seu cartão de crédito não serão armazenadas em Nossos Ambientes, sendo enviadas diretamente à empresa terceira responsável pela transação financeira.</p>
            <br/><p><strong>Nós</strong> poderemos, a qualquer momento, incluir novos meios de pagamento ou limitar ou excluir os existentes, sem que isto implique na necessidade de prévio aviso a qualquer Usuário.</p>
            <br/><p>Com a aprovação dos valores apresentados, haverá a cobrança do valor previamente informado através do cartão de crédito selecionado ou da emissão de boleto bancário.</p>
            <br/><p>Eventuais erros de pagamento poderão ser reportados aos Canais de Atendimento. Note, entretanto, que há a possibilidade que alguns destes eventuais erros sejam dirimidos diretamente perante a instituição financeira pertinente, como, por exemplo, a emissora do cartão.</p>
            <br/><p style={{ marginTop: '0' }}><strong>ATENÇÃO!</strong> As operações realizadas por meio da <em>internet </em>requerem cuidados e cautelas, uma vez que podem ser vulneráveis a ações de terceiros, portanto <strong>Você</strong> deve zelar para que todas as ações sejam realizadas em ambientes seguros com equipamentos e sistemas devidamente atualizados e protegidos.</p>
            <br/><p><strong>Notificações.</strong> <strong>Você</strong>, quando logado em Nossos Ambientes, receberá notificações a respeito de novas atividades vinculadas à sua Conta de Acesso.</p>
            <br/><p><strong>Enquetes.</strong> Quando da necessidade de resposta à enquete proposta pela Instituição de Ensino, <strong>Você</strong> reconhece e está de acordo que suas respostas fornecidas configurarão sua opinião e concordância com o assunto discutido, tendo validade e representando sua assinatura, nos termos do artigo 10, §2º da Medida Provisória 2.200-2/2001.</p>
            <br/><p><strong>Integridade das informações.</strong> <strong>Você</strong> é o único responsável pela integridade e veracidade das informações inseridas por <strong>Você</strong> em Nossos Ambientes, respondendo civil e criminalmente pelos efeitos advindos de uso irregular de informações de terceiros ou informações falsas.</p>
            <br/><p><strong>Você</strong> compromete-se a, sempre que houver alteração de seus dados, a nos informar, através da área logada em Nossos Ambientes ou dos Canais de Atendimento, para fins de atualização.</p>
            <br/><p><strong>CONTEÚDO, DADOS SUBMETIDOS E PROPRIEDADE INTELECTUAL</strong></p>
            <br/><p><strong>Conteúdo Informativo.</strong> Exceto se estabelecido em contrário por estes Termos, todas as informações, notícias, imagens, marcas e informações de mercado disponibilizados por <strong>Nós</strong> em Nossos Ambientes, em qualquer idioma ou forma, é de nossa exclusiva propriedade ou licença (“Conteúdo Informativo”). O uso de Nossos Ambientes por <strong>Você</strong> não gera qualquer presunção de transferência dessa propriedade ou licença. Por essa razão, <strong>Você</strong> não deverá comercializar, no todo ou em parte, o Conteúdo Informativo por quaisquer meios, onerosamente ou não, sabendo que se o fizer poderá ser penalizado civil e penalmente por <strong>Nós</strong> e/ou por qualquer terceiro titular do Conteúdo Informativo.</p>
            <br/><p>Adicionalmente ao disposto acima, destacamos que as funcionalidades que compõem Nossos Ambientes são oferecidas na modalidade de prestação de serviços e licenciamento de <em>software</em>, não conferindo a <strong>Você</strong> nenhum direito sobre nosso <em>software</em> ou sobre suas estruturas de informática.</p>
            <br/><p>A eventual remoção, bloqueio ou suspensão de qualquer Conteúdo Informativo ou funcionalidade de Nossos Ambientes em decorrência de alguma reclamação deverá ser sempre compreendida como demonstração de boa-fé e intenção de solução amigável de conflitos, jamais como reconhecimento de culpa ou de qualquer infração por <strong>Nós</strong> a direito de terceiro.</p>
            <br/><p><strong>Dados e Informações Compartilhados.</strong> <strong>Você</strong> é e continuará sendo o titular e proprietário de todos os dados e informações submetidos e/ou compartilhados em Nossos Ambientes por <strong>Você</strong> (“Dados e Informações”), bem como será o exclusivo responsável por eles.</p>
            <br/><p>Os Dados e Informações não constituirão nossa opinião, tampouco declaração de concordância com o que houver sido submetido e/ou compartilhado em Nossos Ambientes.</p>
            <br/><p>Todos os Dados e Informações submetidos e/ou compartilhados serão mantidos dentro de Nossos Ambientes, sendo vedado seu compartilhamento pelos demais usuários sem autorização.</p>
            <br/><p><strong>Legalidade dos Dados e Informações.</strong> <strong>Nós</strong> não nos obrigaremos a processar ou tratar quaisquer dos Dados e Informações se houver razões para crer que tal processamento ou tratamento possa nos imputar qualquer infração de qualquer lei aplicável ou que Nossos Ambientes estejam sendo utilizados, sob nosso critério exclusivo, para quaisquer fins ilegais, ilícitos ou contrários à moralidade.</p>
            <br/><p><strong>Ausência de garantias quanto aos Dados e Informações e ao Conteúdo Informativo.</strong> <strong>Nós</strong> não garantimos a qualidade, coerência, integridade, forma, atualização ou fonte do Conteúdo Informativo ou dos Dados e Informações, pois não controlamos, verificamos ou fazemos juízo do Conteúdo Informativo, bem como dos Dados e Informações. <strong>Nós</strong> não somos responsáveis, portanto, por quaisquer inveracidade, desatualização ou erro do Conteúdo Informativo ou dos Dados e Informações, bem como não somos responsáveis por quaisquer danos oriundos do Conteúdo Informativo e dos Dados e Informações, a qualquer título.</p>
            <br/><p><strong>Você</strong> fica ciente e concorda que <strong>Nós</strong> não possuímos qualquer obrigação real ou presumida de publicar qualquer Conteúdo Informativo ou Dados e Informações, bem como poderemos remover tal Conteúdo Informativo ou Dados e Informações com ou sem aviso prévio.</p>
            <br/><p>Adicionalmente ao disposto na cláusula acima, <strong>Nós</strong> nos reservamos ao direito de não publicar ou excluir Dados e Informações que:</p>
            <br/><p>1.  Ofendam a honra, imagem, dignidade e reputação de terceiros;</p>
            <br/><p>2.  Possuam conteúdos ligados à pornografia, pedofilia e matérias de cunho sexual;</p>
            <br/><p>3.  Contenham conteúdos relacionados à racismo, discriminação de qualquer natureza, práticas de <em>bullying</em>, <em>stalking</em> ou qualquer outra espécie de constrangimento ilegal ou assédio;</p>
            <br/><p>4.  Representem manifesta violação a direito autoral ou direito de imagem;</p>
            <br/><p>5.  Instiguem ou façam apologia à prática de crimes de qualquer natureza;</p>
            <br/><p>6.  Incentivem a prática de atos contrários à lei, à ordem pública e aos bons costumes;</p>
            <br/><p>7.  Disseminem a ideia de uso da violência, ódio ou qualquer outra forma de agressão ao bem-estar físico de alguém;</p>
            <br/><p>8.  Objetivem a realização de atividade comercial similar à nossa ou angariação de recursos por publicidade de terceiros, desviando-se da real intenção de nossos serviços; e</p>
            <br/><p>9.  Demonstrem erros ou suspeitas de equívocos.</p>
            <br/><p><strong>Armazenamento.</strong> <strong>Nós</strong> não prestamos e os Nossos Ambientes não constituem serviço de armazenamento, portanto <strong>Nós</strong> não possuímos a obrigação de armazenar, reter ou fornecer à <strong>Você</strong> ou a quaisquer terceiros cópia dos Dados e Informações ou qualquer Conteúdo Informativo.</p>
            <br/><p>Não obstante, ressaltamos que é de sua inteira responsabilidade a realização de <em>backup</em> dos Dados e Informações relevantes para <strong>Você</strong>.</p>
            <br/><p><strong>Não mineração.</strong> É vedada a utilização de <em>softwares</em> de mineração de dados de Nossos Ambientes, de qualquer tipo ou espécie, além de outro aqui não tipificado que atue de modo similar.</p>
            <br/><p><strong>Indenização.</strong> Na incidência de danos a <strong>Nós</strong> ou a terceiros, o responsável se compromete a arcar com todas as obrigações de indenizar o sujeito lesado, inclusive aqueles cuja origem for de atos praticados por meio de seu acesso aos Nossos Ambientes, assumindo o polo passivo de ação judicial ou procedimento administrativo e requerendo nossa exclusão, devendo arcar totalmente com as despesas e custas processuais atinentes, deixando-nos livre de prejuízos e ônus.</p>
            <br/><p><strong>Integridade de Nossos Ambientes.</strong> <strong>Você</strong> compromete-se a não acessar áreas de programação de Nossos Ambientes, seu banco de dados, códigos fonte ou qualquer outro conjunto de dados disponíveis nestes ambientes, bem como não realizar ou permitir engenharia reversa, nem traduzir, decompilar, copiar, modificar, reproduzir, alugar, sublicenciar, publicar, divulgar, transmitir, emprestar, distribuir ou, de outra maneira, dispor inapropriadamente das funcionalidades de Nossos Ambientes.</p>
            <br/><p><strong>ISENÇÃO E LIMITAÇÕES DE RESPONSABILIDADE</strong></p>
            <br/><p><strong>Disponibilidade de Nossos Ambientes.</strong> <strong>Nós</strong> envidamos esforços para a manutenção da disponibilidade contínua e permanente de Nossos Ambientes. No entanto, pode ocorrer, eventualmente, alguma indisponibilidade temporária decorrente de manutenção necessária ou mesmo gerada por motivo de força maior, como desastres naturais, falhas nos sistemas de comunicação e acesso à <em>internet</em>, ataques cibernético invasivos, ou quaisquer fatos de terceiro que fogem da esfera de nossa vigilância e responsabilidade.</p>
            <br/><p>Se isso ocorrer, <strong>Nós</strong> faremos o que estiver ao nosso alcance para restabelecer o acesso aos Nossos Ambientes o mais breve possível, dentro das limitações técnicas de nossos serviços e serviços de terceiros, dos quais dependemos para ficar <em>online</em>. Por essa razão, <strong>Você</strong> fica ciente que não poderá pleitear indenização ou reparação de danos no caso de Nossos Ambientes permanecerem fora do ar, independente da motivação.</p>
            <br/><p>Eventuais procedimentos de manutenção que acarretem a indisponibilidade de Nossos Ambientes por longos períodos serão informados por meio de nossos canais oficiais.</p>
            <br/><p>Sem prejuízo de outras condições destes Termos, <strong>Nós</strong> não nos responsabilizaremos:</p>
            <br/><p>1.  Por quaisquer problemas, <em>bugs</em>, <em>glitches</em> ou funcionamentos indevidos que ocorrerem nos seus dispositivos e equipamentos;</p>
            <br/><p>2.  Por qualquer dano direto ou indireto ocasionado por eventos de terceiros, a exemplo, mas não se limitando a ataque de <em>hackers</em>, falhas no sistema, no servidor ou na conexão à <em>internet</em>, inclusive por ações de <em>softwares</em> que possam, de algum modo, danificar ativos físicos ou lógicos em decorrência do acesso, utilização ou navegação em Nossos Ambientes, bem como a transferência de dados, arquivos, imagens, textos, áudios ou vídeos contidos em Nossos Ambientes;</p>
            <br/><p>3.  Pela navegação em quaisquer <em>links </em>externos disponibilizados em Nossos Ambientes, sendo seu dever, a leitura dos Termos e Condições de Uso e da Política de Privacidade do recurso acessado e agir conforme o determinado;</p>
            <br/><p>4.  Por verificar, controlar, aprovar ou garantir a adequação ou exatidão das informações ou dados disponibilizados em tais <em>links</em>, não sendo, portanto, responsáveis por prejuízos, perdas ou danos ocorridos pela visita de tais <em>websites</em>, cabendo ao interessado verificar a confiabilidade das informações e dados ali exibidos antes de tomar alguma decisão ou praticar algum ato; e</p>
            <br/><p>5.  Por eventuais vazamentos de Dados e Informações não autorizados realizados por qualquer usuário.</p>
            <br/><p>Os Dados e Informações submetidos e/ou compartilhados em Nossos Ambientes são armazenados em servidores seguros, de forma criptografada, com a utilização de medidas de segurança da informação constantemente atualizadas. Os Dados e Informações serão mantidos como confidenciais e serão adotadas todas as medidas possíveis contra perda, roubo, uso indevido, alteração e acesso não autorizado. Neste sentindo, destacados que <strong>Nós</strong> não nos responsabilizaremos por eventuais prejuízos que sejam decorrentes da divulgação de tais Dados e Informações por parte de terceiros que utilizem a rede pública ou a <em>internet</em>, subvertendo os sistemas de segurança para acessar as informações do Usuário.</p>
            <br/><p><strong>RECOMENDAÇÕES DE SEGURANÇA</strong></p>
            <br/><p><strong>Recomendações gerais.</strong> Para acessar Nossos Ambientes de forma segura e utilizar nossas funcionalidades de forma integral, é de inteira responsabilidade do Usuário dispor de dispositivo e equipamentos compatíveis, serviço de conexão à <em>internet</em> com antivírus e <em>firewall</em> habilitados e <em>softwares</em> devidamente atualizados – tais como navegadores e sistemas operacionais de dispositivos móveis – além da adoção de medidas de segurança mínima, como a adoção de uma senha forte para <em>Login</em> em sua Conta de Acesso.</p>
            <br/><p>Entende-se por senha forte aquela que:</p>
            <br/><p>1.  Possui ao menos 8 (oito) caracteres, sendo 1 (uma) letra maiúscula, 1 (uma) letra minúscula e 1 (um) número, símbolo ou caractere especial;</p>
            <br/><p>2.  Não representa sequências numéricas relacionadas às informações pessoais ou de fácil dedução, tais como elementos do próprio nome, datas comemorativas ou repetição de caracteres iguais; e</p>
            <br/><p>3.  Não é reutilizada em aplicativos, portais e plataformas de terceiros.</p>
            <br/><p>É de sua responsabilidade deixar seus sistemas <em>AntiSpam</em>, filtros similares ou configurações de redirecionamento de mensagens ou notificações ajustados de modo que não interfiram no recebimento dos comunicados e materiais enviados pelos demais usuários através do uso de nossos serviços.</p>
            <br/><p><strong>Acesso ao Portal ClassApp.</strong> Sempre que acessar o Portal ClassApp, verifique se de fato está no ambiente disponibilizado por <strong>Nós</strong>, ações de <em>hackers</em> podem em uma tentativa de roubar seus dados replicarem uma cópia do Portal ClassApp ou áreas deste.</p>
            <br/><p>Para verificar a integridade e veracidade do Portal ClassApp, clique no cadeado presente na barra de endereço do navegador, verificando os certificados presentes e informados.</p>
            <br/><p>Caso o navegador não apresente os certificados e/ou retorne com a mensagem de que o acesso não é seguro, interrompa imediatamente o uso do Portal ClassApp e nos comunique para que as devidas providências sejam tomadas.</p>
            <br/><p><strong>Acesso não autorizado.</strong> <strong>Nós</strong> não seremos responsáveis por eventual acesso de terceiros não autorizados a sua Conta de Acesso, cabendo somente à <strong>Você</strong> a responsabilidade pela guarda de <em>login</em> e senha em local seguro, assim como o não compartilhamento destas informações com terceiros.</p>
            <br/><p>Não nos caberá também a responsabilidade pelo acesso não autorizado proveniente de ataques realizados por <em>hackers</em> ou devido a ações de vírus que venham a acessar as suas informações e seu cadastro.</p>
            <br/><p>Caso <strong>Você</strong> constate ou desconfie que seu cadastro está sendo utilizado por terceiros não autorizados ou que tenha sido vítima de ataque de <em>hackers</em> ou de programas e/ou arquivos maliciosos (vírus), entre em contato imediatamente conosco através dos Canais de Atendimento, para que possam ser tomadas as devidas providências.</p>
            <br/><p><strong>PENALIDADES</strong></p>
            <br/><p><strong>Penalidades.</strong> O descumprimento de qualquer cláusula destes Termos, poderá gerar: (i) suspensão temporária de Nossos Ambientes para seu <em>Login</em> e consequente impossibilidade de usufruir de Nossos Ambientes; (ii) bloqueio e exclusão de sua Conta de Acesso e/ou (iii) responsabilização nas esferas cível e penal.</p>
            <br/><p><strong>Você</strong> reconhece desde já e compromete-se, durante a utilização de Nossos Ambientes, a não realizar quaisquer ações que atentem contra a moralidade, a ética e boa-fé, obrigando-se a em nenhuma hipótese divulgar, instigar ou auxiliar a prática de atos ilícitos ou fraudulentos, devendo combatê-los sempre que tomar conhecimento sob pena das previsões da cláusula 8.1 acima.</p>
            <br/><p><strong>OUTRAS DISPOSIÇÕES</strong></p>
            <br/><p><strong>Canais de Atendimento.</strong> <strong>Você</strong> poderá utilizar os Canais de Atendimento disponibilizados em Nossos Ambientes toda vez que presenciar ou verificar conteúdo impróprio em Nossos Ambientes, seja ele notadamente ilícito ou contrário às regras de uso estipuladas nestes Termos.</p>
            <br/><p>Eventual denúncia realizada em nossos Canais de Atendimento será sigilosa e preservará a sua identidade.</p>
            <br/><p>Buscamos agir de forma diligente e imediata nos casos de denúncias feitas por <strong>Você</strong> relacionadas à conteúdos que violem os princípios da ética, moral e bons costumes, que sejam ilícitos e que envolvam, especialmente, menores de idade.</p>
            <br/><p><strong>Atualização dos Termos.</strong> Estes Termos estão sujeitos a constante melhoria e aprimoramento. Assim, <strong>Nós</strong> nos reservamos ao direito de modificá-los a qualquer momento, conforme sua finalidade ou conveniência, tal qual para adequação e conformidade legal de disposição de lei ou norma que tenha força jurídica equivalente, incluindo de órgãos reguladores, ou seja, <strong>Você</strong> deverá verificá-los sempre que efetuar o acesso em Nossos Ambientes.</p>
            <br/><p>Ocorrendo atualizações nestes Termos, <strong>Nós</strong> notificaremos <strong>Você</strong> pelas ferramentas disponíveis em Nossos Ambientes ou pelos meios de contato fornecidos por <strong>Você</strong>.</p>
            <br/><p>Ao navegar por Nossos Ambientes e utilizar suas funcionalidades, <strong>Você</strong> aceita guiar-se pelos Termos que se encontram vigentes na data de acesso. Por isso, é recomendável que <strong>Você</strong> se atualize das condições vigentes.</p>
            <br/><p><strong>Novação e renúncia.</strong> A tolerância do eventual descumprimento de quaisquer condições destes Termos não constituirá renúncia ou novação nem nos impedirá de exigir estas condições a qualquer tempo.</p>
            <br/><p><strong>Nulidade.</strong> Se alguma disposição destes Termos for julgada inaplicável ou sem efeito, o restante continuará a viger normalmente.</p>
            <br/><p><strong>Comunicação.</strong> <strong>Você</strong> reconhece que toda comunicação realizada por <em>e-mail</em> ou telefone (ao endereço de <em>e-mail</em> e número de telefone/celular informados no seu cadastro) é válida, eficaz e suficiente para a divulgação de qualquer assunto que se refira aos Nossos Ambientes.</p>
            <br/><p><strong>Data e hora.</strong> Nossos Ambientes possuem como base a data e horário oficial de Brasília (UTC/GMT -03:00).</p>
            <br/><p><strong>Lei aplicável e foro.</strong> Essa Política será interpretada segundo a legislação brasileira, no idioma português, sendo eleito o foro do seu domicílio para dirimir qualquer controvérsia que envolva este documento, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.</p>
            <br/><p><strong>Você</strong>, caso não possua domicílio no Brasil, e em razão dos serviços oferecidos por <strong>Nós</strong> ser apenas em território nacional, se submete à legislação brasileira, concordando, portanto, que em havendo litígio a ser solucionado, a ação deverá ser proposta no Foro da Comarca de Limeira, Estado de São Paulo.</p>
          </section>
        } 
        {
          entityTypes.includes('staff') &&
          <section>
            <div id="terms-staff">
              <h2 id="h.30j0zll">
                TERMOS E CONDIÇÕES DE USO DA CLASSAPP PARA FUNCIONÁRIO DA INSTITUIÇÃO DE
                ENSINO
              </h2>
              <br/><p>Atualizada em 19 de julho de 2021.</p>
              <br/><p>Bem-vindo(a) à <b>ClassApp</b>!</p>
              <br/><p>
                A seguir são apresentados os Termos e Condições de Uso (“Termos”) contendo
                as principais regras que devem ser observadas por <b>Você </b> ao acessar e
                utilizar as funcionalidades disponíveis na área logada do site
                institucional, disponível no <i>link</i> &lt;www.classapp.com.br&gt;
                (“Portal ClassApp”), bem como do aplicativo (“Aplicativo ClassApp”), em
                conjunto denominados de “Nossos Ambientes”, pertencentes a:
              </p>
              <br/><p><b>CLASSAPP SISTEMAS LTDA.</b>, (“<b>Nós</b> ”)</p>
              <br/><p>RUA GUARARAPES, Nº 00136, , KUHL -VL. - LIMEIRA/SP - CEP: 13480-000</p>
              <br/><p>CNPJ: 21.560.411/0001-01</p>
              <br/><p>
                Como condição para acesso e uso das funcionalidades existentes em Nossos
                Ambientes, <b>Você</b> declara que fez a leitura completa e atenta das
                regras deste documento, estando plenamente ciente e de acordo com elas.
              </p>
              <br/><p>
                Para acesso aos Nossos Ambientes, poderão ser requeridas determinadas
                informações sobre <b>Você</b> , com o intuito de garantir a segurança e a
                identificação no uso de Nossos Ambientes. Não se preocupe, a utilização de
                seus dados está regulada de acordo com nossa Política de Privacidade e
                Tratamento de Dados, que pode ser acessada por <b>Você</b> a qualquer
                momento.
              </p>
              <br/><p><b>DEFINIÇÕES</b> </p>
              
              <br/><p><b>AntiSpam</b><b>:</b> Sistema que evita correspondências não
              desejadas, como publicidade em massa, pelo bloqueio de mensagem ou
              direcionamento destas para pastas específicas.</p>
            
              <br/><p><b>Aplicativo ClassApp:</b> Aplicativo disponibilizado às Instituições
              de Ensino com a finalidade de proporcionar uma alternativa conveniente
              para que estas interajam com nossos conteúdos e utilizem as
              funcionalidades e serviços oferecidos, podendo ser baixado por meio do <i>App Store</i> e <i>Google Play</i>.</p>

              <br/><p><b>Backup</b><b>:</b> Salvaguarda de informações, realizada por meio da
              reprodução e/ou espelhamento de uma base de arquivos, com a finalidade
              de plena capacidade de recuperação em caso de incidente ou necessidade
              de <i>restore</i>, ou constituição de infraestrutura de acionamento
              imediato em caso de incidente ou necessidade justificada pelo Usuário ou
              Instituições de Ensino.</p>
              
              <br/><p><b>Canais de Atendimento:</b> Meios de contato disponibilizados por <b>Nós</b> , para o envio de dúvidas e sugestões relacionadas aos Nossos
              Ambientes.</p>
              
              <br/><p><b>Conta de Acesso:</b> Credencial de um Usuário que permite acesso à
              área restrita e às funcionalidades exclusivas existentes em Nossos
              Ambientes, definida pela combinação de <i>e-mail</i> ou número de
              celular e senha.</p>
              
              <br/><p><b>Instituições de Ensino:</b> Instituições de ensino clientes, que
              contratam nossos serviços para utilização por seus funcionários,
              representantes, terceiros, alunos e responsáveis por alunos.</p>
              
              <br/><p><b>Layout</b><b>:</b> Conjunto compreendido entre aparência, <i>design</i> e fluxos de Nossos Ambientes.</p>
              
              <br/><p><b>Link</b><b>:</b> Terminologia para endereço de <i>internet</i>.</p>
              
              <br/><p><b>Login</b><b>:</b> Processo para acessar um sistema informático
              restrito feito através da autenticação ou identificação do Usuário,
              usando credenciais previamente cadastradas no sistema.</p>
              
              <br/><p><b>Portal ClassApp:</b> Designa a área logada constante em nosso site
              institucional, disponível através do endereço eletrônico
              &lt;www.classapp.com.br&gt;.</p>
              
              <br/><p><b>Usuários ou Você:</b> Pessoas físicas, que desejam experimentar
              nossos serviços disponíveis em Nossos Ambientes através de uma
              simulação, utilizando-se o perfil funcionário professor ou pessoas
              autorizadas pelas Instituições de Ensino a utilizar Nossos Ambientes de 
              forma efetiva, através do perfil de acesso como funcionário.</p>
              <br/><p><b>FUNCIONALIDADES DISPONÍVEIS EM NOSSOS AMBIENTES</b> </p>
              <br/><p><b>Restrição de acesso.</b> As funcionalidades disponíveis em Nossos
              Ambientes são destinadas a funcionários interessados em experimentar
              nossos serviços através de uma simulação ou previamente cadastrados em
              nosso sistema pelas Instituições de Ensino.</p>
              <br/><p><b>Nós</b> podemos recusar, não aprovar ou cancelar a criação de seu
              cadastro caso <b>Você</b> se utilize de dados incorretos ou falsos. O
              mesmo será aplicável durante a utilização de Nossos Ambientes, caso seja
              verificado seu uso de forma fraudulenta, bem como que tente ou viole
              estes Termos, ou quaisquer legislações vigentes.</p>
              <br/><p>Não obstante, caso seja verificada por <b>Nós</b> a existência de
              atividade suspeita ou transação que apresente considerável risco de
              fraude, <b>Nós</b> poderemos a qualquer tempo e a nosso exclusivo
              critério, solicitar informações adicionais a <b>Você</b> , sendo que,
              qualquer erro ou atraso no envio destas informações que gere prejuízo ou
              dano de qualquer natureza, será de sua inteira responsabilidade.</p>
              
              <br/><p><b>Elegibilidade.</b> <b>Nós</b>  podemos alterar os critérios de
              elegibilidade de acesso e utilização de Nossos Ambientes a qualquer
              momento e ao nosso critério, sem a necessidade de comunicação ou aviso
              prévio a <b>Você</b> ou a qualquer terceiro, sendo de extrema
              importância que <b>Você</b> , ao fazer uso das funcionalidades existentes
              em Nossos Ambientes, sempre verifique as regras vigentes.</p>
                  
              <br/><p><b>E-mails</b><b>.</b> Ao fornecer seus dados para criação de uma Conta
              de Acesso em Nossos Ambientes, <b>Você</b> está ciente que, nos termos
              do previsto na Política de Privacidade e Tratamento de Dados, poderá
              receber, no <i>e-mail</i> e/ou telefone informados, mensagens com avisos
              e conteúdos publicitários enviados por <b>Nós</b>. Lembrando que,
              através dos Canais de Atendimento, a qualquer tempo, <b>Você</b> terá
              sempre a opção de suspender o recebimento destas comunicações, desde que
              não prejudique o contato imprescindível à resolução da solicitação
              realizada por <b>Você</b> em Nossos Ambientes.</p>

              <br/><p><b>Apresentação de Nossos Ambientes.</b> Nossos Ambientes e as
              funcionalidades neles existentes são apresentadas a <b>Você</b> na
              maneira como estão disponíveis no momento do acesso, mas podem passar
              por constantes aprimoramentos e atualizações sem a necessidade de prévio
              aviso.</p>
            
              <br/><p>Neste caso, <b>Nós</b> nos comprometemos a preservar as funcionalidades
              de Nossos Ambientes com esforços razoáveis, utilizando <i>layout</i> que
              respeita a usabilidade e navegabilidade, sempre que possível, bem como a
              exibir as funcionalidades de maneira clara, completa, precisa e
              suficiente de modo que exista uma correta percepção do que está sendo
              praticado e proteger, por meio do estado da técnica disponível, os dados
              coletados pelas funcionalidades disponibilizadas, garantindo seu sigilo.</p>
              
              <br/><p><b>ACESSO AOS NOSSOS AMBIENTES</b> </p>
              
              <br/><p><b>Simulação em Nossos Ambientes.</b> Para experimentar as
              funcionalidades disponíveis em Nossos Ambientes, <b>Você</b> poderá
              realizar a criação de uma Conta de Acesso, onde serão solicitadas
              informações que deverão ser prestadas unicamente por <b>Você</b>.</p>
                
              
              <br/><p>Será necessário que <b>Você</b> informe: (i) seu endereço de <i>e-mail</i> e código de acesso enviado para sua caixa de entrada; ou
              (ii) seu número de celular e código de acesso enviado via SMS. Este
              código de acesso será gerado a cada vez que <b>Você</b> precisar acessar
              Nossos Ambientes e não possuir uma senha cadastrada.</p>
                
              <br/><p><b>Utilização efetiva de Nossos Ambientes.</b> Para utilização das
              funcionalidades disponíveis em Nossos Ambientes de forma efetiva, <b>Nós</b> realizaremos o pré-cadastro de sua Conta de Acesso, mediante
              o compartilhamento de seus dados coletados pela Instituição de Ensino.</p>
                
              <br/><p>Para ativação de sua Conta de Acesso, será necessário que <b>Você</b> adote os mesmos procedimentos descritos na cláusula 3.1.1.
              acima.</p>
                
              
              <br/><p>
              Após seu primeiro acesso em Nossos Ambientes, será possível prosseguir
              com a criação de uma senha de acesso, a fim de garantir maior segurança
              aos seus dados.
              </p>
              <br/><p>
                Posteriormente ao seu acesso, será disponibilizada a <b>Você</b> a sua
                área logada, na qual <b>Você</b> poderá incluir uma foto de perfil de
                sua escolha.
              </p>
              <br/><p>
                <b>Validação.</b> As validações dos acessos realizados por <b>Você</b> são importantes para que <b>Nós</b> possamos manter a
                autenticidade da sua Conta de Acesso, prevenindo que esta seja utilizada
                por terceiros mal-intencionados, além de ser uma forma segura que
                permite a alteração de suas informações, acesso aos dados, utilização
                das funcionalidades, entre outros atos necessários no âmbito da
                navegação de Nossos Ambientes.
              </p>
              <br/><p><b>USO DE NOSSOS AMBIENTES POR VOCÊ</b> </p>
              <br/><p>
                <b>Sigilo dos dados do cadastro.</b> <b>Você</b> deverá manter o sigilo
                dos dados de sua Conta de Acesso em relação a terceiros e utilizá-los de
                modo individual e intransferível, não disponibilizando, divulgando ou
                compartilhando sua senha ou qualquer mecanismo de autenticação com quem
                quer que seja. Caso suspeite que a confidencialidade de sua senha foi
                violada, <b>Você</b> deverá proceder com sua troca ou atualização o mais
                rápido possível, além de nos comunicar através dos Canais de
                Atendimento, para que possam ser tomadas as devidas providências, quando
                aplicável.
              </p>
              <br/><p>
                <b>Você</b> está ciente e reconhece que todos os acessos e operações
                realizadas em Nossos Ambientes após seu acesso bem-sucedido são
                interpretados como sendo de sua responsabilidade, de forma
                incontestável, inclusive aqueles derivados de uso indevido ou de
                divulgação deste para terceiros.
              </p>
              <br/><p>
                <b>Acesso aos Nossos Ambientes.</b> Para acesso aos Nossos Ambientes, <b>Você</b> deve utilizar-se da <i>internet</i> e:
              </p>
              <br/><p>
                Efetuar o acesso ao <i>link </i><a
                  href="https://www.google.com/url?q=http://www.classapp.com.br/&amp;sa=D&amp;source=editors&amp;ust=1733780022844755&amp;usg=AOvVaw2eAO_JNu0FjBXun41knauO">www.classapp.com.br</a>,
                para interagir no Portal ClassApp; ou
              </p>
              <br/><p>
                Realizar o <i>download</i>, através da <i>App Store </i>ou <i>Google Play</i>, ao seu dispositivo móvel, do Aplicativo ClassApp.
              </p>
              <br/><p>
                Não sendo permitido o acesso por nenhum outro meio diverso não
                autorizado por <b>Nós</b>.
              </p>
              <br/><p>
                <b>Funcionalidades de Nossos Ambientes.</b> Após a realização de sua
                identificação e autenticação através do <i>Login</i>, <b>Você</b> poderá
                utilizar Nossos Ambientes, onde poderá encontrar e ter acesso a diversas
                funcionalidades e conteúdos informativos criados para <b>Você</b>.
              </p>
              <br/><p>
                <b>Funcionalidades disponíveis no Aplicativo ClassApp.</b> As
                funcionalidades disponíveis no Aplicativo ClassApp têm, por objetivo,
                permitir que <b>Você</b> : (i) envie mensagens aos demais usuários,
                podendo esta conter formulários, cobranças de valores, enquetes,
                compromissos, relatórios, áudios, arquivos e demais formatos de mídias;
                (ii) efetue postagens e visualize aquelas realizadas pelos demais
                usuários através da aba “Momentos”; (iii) acesse o calendário e crie
                eventos; (iv) pesquise o histórico de conversas realizadas entre <b>Você</b> e demais usuários; (v) efetue o acompanhamento do <i>status</i> de utilização de nossos serviços por <b>Você</b> , através
                da aba “<i>Dashboard</i>”; e (vi) remova a Conta de Acesso vinculada à <b>Você</b>.
              </p>
              <br/><p>
                <b>Funcionalidades disponíveis no Portal ClassApp.</b> Além das opções
                descritas no item 4.4.1. acima, <b>Você</b> também encontrará
                disponíveis no Portal ClassApp as seguintes funcionalidades: (i) edição
                de suas informações de perfil; (ii) gestão das Contas de Acesso de
                Usuários vinculados ao perfil do funcionário, podendo criar e excluir
                tais Contas, bem como atualizar suas informações; além de outras
                funcionalidades que poderão ser concedidas mediante permissão da
                Instituição de Ensino.
              </p>
              <br/><p>
                Para utilização da funcionalidade de compartilhamento de mídias, <b>Nós</b> poderemos solicitar acesso ao seu áudio, câmera ou galeria de
                imagens. Caso não permita o acesso, o serviço não poderá ser executado.
              </p>
              <br/><p>
                <b>Notificações.</b> <b>Você</b> , quando logado em Nossos Ambientes,
                receberá notificações a respeito de novas atividades vinculadas à sua
                Conta de Acesso.
              </p>
              <br/><p>
                <b>Integridade das informações.</b> <b>Você</b> é o único responsável
                pela integridade e veracidade das informações inseridas por <b>Você</b> em Nossos Ambientes, respondendo civil e criminalmente pelos
                efeitos advindos de uso irregular de informações de terceiros ou
                informações falsas.
              </p>
              <br/><p>
                <b>Você</b> compromete-se a, sempre que houver alteração de seus dados,
                a nos informar, através da área logada em Nossos Ambientes ou dos Canais
                de Atendimento, para fins de atualização.
              </p>
              <br/><p><b>CONTEÚDO, DADOS SUBMETIDOS E PROPRIEDADE INTELECTUAL</b> </p>
              <br/><p>
                <b>Conteúdo Informativo.</b> Exceto se estabelecido em contrário por
                estes Termos, todas as informações, notícias, imagens, marcas e
                informações de mercado disponibilizados por <b>Nós</b> em Nossos
                Ambientes, em qualquer idioma ou forma, é de nossa exclusiva propriedade
                ou licença (“Conteúdo Informativo”). O uso de Nossos Ambientes por <b>Você</b> não gera qualquer presunção de transferência dessa
                propriedade ou licença. Por essa razão, <b>Você</b> não deverá
                comercializar, no todo ou em parte, o Conteúdo Informativo por quaisquer
                meios, onerosamente ou não, sabendo que se o fizer poderá ser penalizado
                civil e penalmente por <b>Nós</b> e/ou por qualquer terceiro titular do
                Conteúdo Informativo.
              </p>
              <br/><p>
                Adicionalmente ao disposto acima, destacamos que as funcionalidades que
                compõem Nossos Ambientes são oferecidas na modalidade de prestação de
                serviços e licenciamento de <i>software</i>, não conferindo a <b>Você</b> nenhum direito sobre nosso <i>software</i> ou sobre suas
                estruturas de informática.
              </p>
              <br/><p>
                A eventual remoção, bloqueio ou suspensão de qualquer Conteúdo
                Informativo ou funcionalidade de Nossos Ambientes em decorrência de
                alguma reclamação deverá ser sempre compreendida como demonstração de
                boa-fé e intenção de solução amigável de conflitos, jamais como
                reconhecimento de culpa ou de qualquer infração por <b>Nós</b> a direito
                de terceiro.
              </p>
              <br/><p>
                <b>Dados e Informações Compartilhados.</b> <b>Você</b> é e continuará
                sendo o titular e proprietário de todos os dados e informações
                submetidos e/ou compartilhados em Nossos Ambientes por <b>Você</b> (“Dados e Informações”), bem como será o exclusivo
                responsável por eles.
              </p>
              <br/><p>
                Os Dados e Informações não constituirão nossa opinião, tampouco
                declaração de concordância com o que houver sido submetido e/ou
                compartilhado em Nossos Ambientes.
              </p>
              <br/><p>
                Todos os Dados e Informações submetidos e/ou compartilhados serão
                mantidos dentro de Nossos Ambientes, sendo vedado seu compartilhamento
                pelos demais usuários sem autorização.
              </p>
              <br/><p>
                <b>Legalidade dos Dados e Informações.</b> <b>Nós</b> não nos
                obrigaremos a processar ou tratar quaisquer dos Dados e Informações se
                houver razões para crer que tal processamento ou tratamento possa nos
                imputar qualquer infração de qualquer lei aplicável ou que Nossos
                Ambientes estejam sendo utilizados, sob nosso critério exclusivo, para
                quaisquer fins ilegais, ilícitos ou contrários à moralidade.
              </p>
              <br/><p>
                <b>Ausência de garantias quanto aos Dados e Informações e ao Conteúdo
                  Informativo.</b> <b>Nós</b> não garantimos a qualidade, coerência, integridade, forma,
                atualização ou fonte do Conteúdo Informativo ou dos Dados e Informações,
                pois não controlamos, verificamos ou fazemos juízo do Conteúdo
                Informativo, bem como dos Dados e Informações. <b>Nós</b> não somos
                responsáveis, portanto, por quaisquer inveracidade, desatualização ou
                erro do Conteúdo Informativo ou dos Dados e Informações, bem como não
                somos responsáveis por quaisquer danos oriundos do Conteúdo Informativo
                e dos Dados e Informações, a qualquer título.
              </p>
              <br/><p>
                <b>Você</b> fica ciente e concorda que <b>Nós</b> não possuímos qualquer
                obrigação real ou presumida de publicar qualquer Conteúdo Informativo ou
                Dados e Informações, bem como poderemos remover tal Conteúdo Informativo
                ou Dados e Informações com ou sem aviso prévio.
              </p>
              <br/><p>
                Adicionalmente ao disposto na cláusula acima, <b>Nós</b> nos reservamos
                ao direito de não publicar ou excluir Dados e Informações que:
              </p>
              <br/><p>1.  Ofendam a honra, imagem, dignidade e reputação de terceiros;</p>
              <br/><p>
                2.  Possuam conteúdos ligados à pornografia, pedofilia e matérias de cunho
                sexual;
              </p>
              <br/><p>
                3.  Contenham conteúdos relacionados à racismo, discriminação de qualquer
                natureza, práticas de <i>bullying</i>, <i>stalking</i> ou qualquer outra
                espécie de constrangimento ilegal ou assédio;
              </p>
              <br/><p>
                4.  Representem manifesta violação a direito autoral ou direito de imagem;
              </p>
              <br/><p>
                5.  Instiguem ou façam apologia à prática de crimes de qualquer natureza;
              </p>
              <br/><p>
                6.  Incentivem a prática de atos contrários à lei, à ordem pública e aos
                bons costumes;
              </p>
              <br/><p>
                7.  Disseminem a ideia de uso da violência, ódio ou qualquer outra forma de
                agressão ao bem-estar físico de alguém;
              </p>
              <br/><p>
                8.  Objetivem a realização de atividade comercial similar à nossa ou
                angariação de recursos por publicidade de terceiros, desviando-se da
                real intenção de nossos serviços; e
              </p>
              <br/><p>9.  Demonstrem erros ou suspeitas de equívocos.</p>
              <br/><p>
                <b>Armazenamento.</b> <b>Nós</b> não prestamos e os Nossos Ambientes não
                constituem serviço de armazenamento, portanto <b>Nós</b> não possuímos a
                obrigação de armazenar, reter ou fornecer à <b>Você</b> ou a quaisquer
                terceiros cópia dos Dados e Informações ou qualquer Conteúdo
                Informativo.
              </p>
              <br/><p>
                Não obstante, ressaltamos que é de sua inteira responsabilidade a
                realização de <i>backup</i> dos Dados e Informações relevantes para <b>Você</b>.
              </p>
              <br/><p>
                <b>Não mineração.</b> É vedada a utilização de <i>softwares</i> de
                mineração de dados de Nossos Ambientes, de qualquer tipo ou espécie,
                além de outro aqui não tipificado que atue de modo similar.
              </p>
              <br/><p>
                <b>Indenização.</b> Na incidência de danos a <b>Nós</b> ou a terceiros,
                o responsável se compromete a arcar com todas as obrigações de indenizar
                o sujeito lesado, inclusive aqueles cuja origem for de atos praticados
                por meio de seu acesso aos Nossos Ambientes, assumindo o polo passivo de
                ação judicial ou procedimento administrativo e requerendo nossa
                exclusão, devendo arcar totalmente com as despesas e custas processuais
                atinentes, deixando-nos livre de prejuízos e ônus.
              </p>
              <br/><p>
                <b>Integridade de Nossos Ambientes.</b> <b>Você</b> compromete-se a não
                acessar áreas de programação de Nossos Ambientes, seu banco de dados,
                códigos fonte ou qualquer outro conjunto de dados disponíveis nestes
                ambientes, bem como não realizar ou permitir engenharia reversa, nem
                traduzir, decompilar, copiar, modificar, reproduzir, alugar,
                sublicenciar, publicar, divulgar, transmitir, emprestar, distribuir ou,
                de outra maneira, dispor inapropriadamente das funcionalidades de Nossos
                Ambientes.
              </p>
              <br/><p><b>ISENÇÃO E LIMITAÇÕES DE RESPONSABILIDADE</b> </p>
              <br/><p>
                <b>Disponibilidade de Nossos Ambientes.</b> <b>Nós</b> envidamos
                esforços para a manutenção da disponibilidade contínua e permanente de
                Nossos Ambientes. No entanto, pode ocorrer, eventualmente, alguma
                indisponibilidade temporária decorrente de manutenção necessária ou
                mesmo gerada por motivo de força maior, como desastres naturais, falhas
                nos sistemas de comunicação e acesso à <i>internet</i>, ataques
                cibernético invasivos, ou quaisquer fatos de terceiro que fogem da
                esfera de nossa vigilância e responsabilidade.
              </p>
              <br/><p>
                Se isso ocorrer, <b>Nós</b> faremos o que estiver ao nosso alcance para
                restabelecer o acesso aos Nossos Ambientes o mais breve possível, dentro
                das limitações técnicas de nossos serviços e serviços de terceiros, dos
                quais dependemos para ficar <i>online</i>. Por essa razão, <b>Você</b> fica ciente que não poderá pleitear indenização ou reparação
                de danos no caso de Nossos Ambientes permanecerem fora do ar,
                independente da motivação.
              </p>
              <br/><p>
                Eventuais procedimentos de manutenção que acarretem a indisponibilidade
                de Nossos Ambientes por longos períodos serão informados por meio de
                nossos canais oficiais.
              </p>
              <br/><p>
                Sem prejuízo de outras condições destes Termos, <b>Nós</b> não nos
                responsabilizaremos:
              </p>
              <br/><p>
                1.  Por quaisquer problemas, <i>bugs</i>, <i>glitches</i> ou funcionamentos
                indevidos que ocorrerem nos seus dispositivos e equipamentos;
              </p>
              <br/><p>
                2.  Por qualquer dano direto ou indireto ocasionado por eventos de
                terceiros, a exemplo, mas não se limitando a ataque de <i>hackers</i>,
                falhas no sistema, no servidor ou na conexão à <i>internet</i>,
                inclusive por ações de <i>softwares</i> que possam, de algum modo,
                danificar ativos físicos ou lógicos em decorrência do acesso, utilização
                ou navegação em Nossos Ambientes, bem como a transferência de dados,
                arquivos, imagens, textos, áudios ou vídeos contidos em Nossos
                Ambientes;
              </p>
              <br/><p>
                3.  Pela navegação em quaisquer <i>links </i> externos disponibilizados em
                Nossos Ambientes, sendo seu dever, a leitura dos Termos e Condições de
                Uso e da Política de Privacidade do recurso acessado e agir conforme o
                determinado;
              </p>
              <br/><p>
                4.  Por verificar, controlar, aprovar ou garantir a adequação ou exatidão
                das informações ou dados disponibilizados em tais <i>links</i>, não
                sendo, portanto, responsáveis por prejuízos, perdas ou danos ocorridos
                pela visita de tais <i>websites</i>, cabendo ao interessado verificar a
                confiabilidade das informações e dados ali exibidos antes de tomar
                alguma decisão ou praticar algum ato; e
              </p>
              <br/><p>
                5.  Por eventuais vazamentos de Dados e Informações não autorizados
                realizados por qualquer usuário.
              </p>
              <br/><p>
                Os Dados e Informações submetidos e/ou compartilhados em Nossos
                Ambientes são armazenados em servidores seguros, de forma criptografada,
                com a utilização de medidas de segurança da informação constantemente
                atualizadas. Os Dados e Informações serão mantidos como confidenciais e
                serão adotadas todas as medidas possíveis contra perda, roubo, uso
                indevido, alteração e acesso não autorizado. Neste sentindo, destacamos
                que <b>Nós</b> não nos responsabilizaremos por eventuais prejuízos que
                sejam decorrentes da divulgação de tais Dados e Informações por parte de
                terceiros que utilizem a rede pública ou a <i>internet</i>, subvertendo
                os sistemas de segurança para acessar as informações do Usuário.
              </p>
              <br/><p><b>RECOMENDAÇÕES DE SEGURANÇA</b> </p>
              <br/><p>
                <b>Recomendações gerais.</b> Para acessar Nossos Ambientes de forma
                segura e utilizar nossas funcionalidades de forma integral, é de inteira
                responsabilidade do Usuário dispor de dispositivo e equipamentos
                compatíveis, serviço de conexão à <i>internet</i> com antivírus e <i>firewall</i> habilitados e <i>softwares</i> devidamente atualizados –
                tais como navegadores e sistemas operacionais de dispositivos móveis –
                além da adoção de medidas de segurança mínima, como a adoção de uma
                senha forte para <i>Login</i> em sua Conta de Acesso.
              </p>
              <br/><p>Entende-se por senha forte aquela que:</p>
              <br/><p>
                1.  Possui ao menos 8 (oito) caracteres, sendo 1 (uma) letra maiúscula, 1
                (uma) letra minúscula e 1 (um) número, símbolo ou caractere especial;
              </p>
              <br/><p>
                2.  Não representa sequências numéricas relacionadas às informações pessoais
                ou de fácil dedução, tais como elementos do próprio nome, datas
                comemorativas ou repetição de caracteres iguais; e
              </p>
              <br/><p>
                3.  Não é reutilizada em aplicativos, portais e plataformas de terceiros.
              </p>
              <br/><p>
                É de sua responsabilidade deixar seus sistemas <i>AntiSpam</i>, filtros
                similares ou configurações de redirecionamento de mensagens ou
                notificações ajustados de modo que não interfiram no recebimento dos
                comunicados e materiais enviados pelos demais usuários através do uso de
                nossos serviços.
              </p>
              <br/><p>
                <b>Acesso ao Portal ClassApp.</b> Sempre que acessar o Portal ClassApp,
                verifique se de fato está no ambiente disponibilizado por <b>Nós</b>,
                ações de <i>hackers</i> podem em uma tentativa de roubar seus dados
                replicarem uma cópia do Portal ClassApp ou áreas deste.
              </p>
              <br/><p>
                Para verificar a integridade e veracidade do Portal ClassApp, clique no
                cadeado presente na barra de endereço do navegador, verificando os
                certificados presentes e informados.
              </p>
              <br/><p>
                Caso o navegador não apresente os certificados e/ou retorne com a
                mensagem de que o acesso não é seguro, interrompa imediatamente o uso do
                Portal ClassApp e nos comunique para que as devidas providências sejam
                tomadas.
              </p>
              <br/><p>
                <b>Acesso não autorizado.</b> <b>Nós</b> não seremos responsáveis por
                eventual acesso de terceiros não autorizados a sua Conta de Acesso,
                cabendo somente à <b>Você</b> a responsabilidade pela guarda de <i>login</i> e senha em local seguro, assim como o não compartilhamento
                destas informações com terceiros.
              </p>
              <br/><p>
                Não nos caberá também a responsabilidade pelo acesso não autorizado
                proveniente de ataques realizados por <i>hackers</i> ou devido a ações
                de vírus que venham a acessar as suas informações e seu cadastro.
              </p>
              <br/><p>
                Caso <b>Você</b> constate ou desconfie que seu cadastro está sendo
                utilizado por terceiros não autorizados ou que tenha sido vítima de
                ataque de <i>hackers</i> ou de programas e/ou arquivos maliciosos
                (vírus), entre em contato imediatamente conosco através dos Canais de
                Atendimento, para que possam ser tomadas as devidas providências.
              </p>
              <br/><p><b>PENALIDADES</b> </p>
              <br/><p>
                <b>Penalidades.</b> O descumprimento de qualquer cláusula destes Termos,
                poderá gerar: (i) suspensão temporária de Nossos Ambientes para seu <i>Login</i> e consequente impossibilidade de usufruir de Nossos
                Ambientes; (ii) bloqueio e exclusão de sua Conta de Acesso e/ou (iii)
                responsabilização nas esferas cível e penal.
              </p>
              <br/><p>
                <b>Você</b> reconhece desde já e compromete-se, durante a utilização de
                Nossos Ambientes, a não realizar quaisquer ações que atentem contra a
                moralidade, a ética e boa-fé, obrigando-se a em nenhuma hipótese
                divulgar, instigar ou auxiliar a prática de atos ilícitos ou
                fraudulentos, devendo combatê-los sempre que tomar conhecimento sob pena
                das previsões da cláusula 8.1 acima.
              </p>
              <br/><p><b>OUTRAS DISPOSIÇÕES</b> </p>
              <br/><p>
                <b>Canais de Atendimento.</b> <b>Você</b> poderá utilizar os Canais de
                Atendimento disponibilizados em Nossos Ambientes toda vez que presenciar
                ou verificar conteúdo impróprio em Nossos Ambientes, seja ele
                notadamente ilícito ou contrário às regras de uso estipuladas nestes
                Termos.
              </p>
              <br/><p>
                Eventual denúncia realizada em nossos Canais de Atendimento será
                sigilosa e preservará a sua identidade.
              </p>
              <br/><p>
                Buscamos agir de forma diligente e imediata nos casos de denúncias
                feitas por <b>Você</b> relacionadas à conteúdos que violem os princípios
                da ética, moral e bons costumes, que sejam ilícitos e que envolvam,
                especialmente, menores de idade.
              </p>
              <br/><p>
                <b>Atualização dos Termos.</b> Estes Termos estão sujeitos a constante
                melhoria e aprimoramento. Assim, <b>Nós</b> nos reservamos ao direito de
                modificá-los a qualquer momento, conforme sua finalidade ou
                conveniência, tal qual para adequação e conformidade legal de disposição
                de lei ou norma que tenha força jurídica equivalente, incluindo de
                órgãos reguladores, ou seja, <b>Você</b> deverá verificá-los sempre que
                efetuar o acesso em Nossos Ambientes.
              </p>
              <br/><p>
                Ocorrendo atualizações nestes Termos, <b>Nós</b> notificaremos <b>Você</b> pelas ferramentas disponíveis em Nossos Ambientes ou pelos
                meios de contato fornecidos por <b>Você</b>.
              </p>
              <br/><p>
                Ao navegar por Nossos Ambientes e utilizar suas funcionalidades, <b>Você</b> aceita guiar-se pelos Termos que se encontram vigentes na
                data de acesso. Por isso, é recomendável que <b>Você</b> se atualize das
                condições vigentes.
              </p>
              <br/><p>
                <b>Novação e renúncia.</b> A tolerância do eventual descumprimento de
                quaisquer condições destes Termos não constituirá renúncia ou novação
                nem nos impedirá de exigir estas condições a qualquer tempo.
              </p>
              <br/><p>
                <b>Nulidade.</b> Se alguma disposição destes Termos for julgada
                inaplicável ou sem efeito, o restante continuará a viger normalmente.
              </p>
              <br/><p>
                <b>Comunicação.</b> <b>Você</b> reconhece que toda comunicação realizada
                por <i>e-mail</i> ou telefone (ao endereço de <i>e-mail</i> e número de
                telefone/celular informados no seu cadastro) é válida, eficaz e
                suficiente para a divulgação de qualquer assunto que se refira aos
                Nossos Ambientes.
              </p>
              <br/><p>
                <b>Data e hora.</b> Nossos Ambientes possuem como base a data e horário
                oficial de Brasília (UTC/GMT -03:00).
              </p>
              <br/><p>
                <b>Lei aplicável e foro.</b> Essa Política será interpretada segundo a
                legislação brasileira, no idioma português, sendo eleito o foro da
                Comarca de Limeira, Estado de São Paulo, para dirimir qualquer
                controvérsia que envolva este documento, salvo ressalva específica de
                competência pessoal, territorial ou funcional pela legislação aplicável.
              </p>
            </div>
          </section>
        }
        {
          entityTypes.includes('admin') &&
          <section>
            <div id="terms-admin">
              <h2 id="h.1fob9te">
                TERMOS E CONDIÇÕES DE USO DA CLASSAPP PARA ADMINISTRADOR DE SISTEMA
              </h2>
              <br/><p>Atualizada em 19 de julho de 2021.</p>
              <br/><p>Bem-vindo(a) à <b>ClassApp</b>!</p>
              <br/><p>
                A seguir são apresentados os Termos e Condições de Uso (“Termos”) contendo
                as principais regras que devem ser observadas por <b>Você </b>ao acessar e
                utilizar as funcionalidades disponíveis na área logada do site
                institucional, disponível no <i>link</i> &lt;www.classapp.com.br&gt;
                (“Portal ClassApp”), bem como do aplicativo (“Aplicativo ClassApp”), em
                conjunto denominados de “Nossos Ambientes”, pertencentes a:
              </p>
              <br/><p><b>CLASSAPP SISTEMAS LTDA.</b>, (“<b>Nós</b>”)</p>
              <br/><p>RUA GUARARAPES, Nº 00136, , KUHL -VL. - LIMEIRA/SP - CEP: 13480-000</p>
              <br/><p>CNPJ: 21.560.411/0001-01</p>
              <br/><p>
                Como condição para acesso e uso das funcionalidades existentes em Nossos
                Ambientes, <b>Você</b> declara que fez a leitura completa e atenta das
                regras deste documento, estando plenamente ciente e de acordo com elas.
              </p>
              <br/><p>
                Para acesso aos Nossos Ambientes, poderão ser requeridas determinadas
                informações sobre <b>Você</b>, com o intuito de garantir a segurança e a
                identificação no uso de Nossos Ambientes. Não se preocupe, a utilização de
                seus dados está regulada de acordo com nossa Política de Privacidade e
                Tratamento de Dados, que pode ser acessada por <b>Você</b> a qualquer
                momento.
              </p>
              <br/><p><b>DEFINIÇÕES</b></p>
            
              <br/><p>
                <b>Administrador/Gerenciador de Sistema:</b> Usuário designado pelas
                Instituições de Ensino, com permissão administrativa para atuar em
                Nossos Ambientes, tendo acesso, controle e capacidade de gerenciamento
                de todas as informações relativas às Instituições de Ensino.
              </p>
              <br/><p>
                <b>AntiSpam</b><b>:</b> Sistema que evita correspondências indesejadas,
                como publicidade em massa, pelo bloqueio de mensagem ou direcionamento
                destas para pastas específicas.
              </p>
              <br/><p>
                <b>Aplicativo ClassApp:</b> Aplicativo disponibilizado às Instituições
                de Ensino com a finalidade de proporcionar uma alternativa conveniente
                para que estas interajam com nossos conteúdos e utilizem as
                funcionalidades e serviços oferecidos, podendo ser baixado por meio do <i>App Store</i> e <i>Google Play</i>.
              </p>
              <br/><p>
                <b>Backup</b><b>:</b> Salvaguarda de informações, realizada por meio da
                reprodução e/ou espelhamento de uma base de arquivos, com a finalidade
                de plena capacidade de recuperação em caso de incidente ou necessidade
                de <i>restore</i>, ou constituição de infraestrutura de acionamento
                imediato em caso de incidente ou necessidade justificada pelo Usuário ou
                Instituições de Ensino.
              </p>
              <br/><p>
                <b>Canais de Atendimento:</b> Meios de contato disponibilizados por <b>Nós</b>, para o envio de dúvidas e sugestões relacionadas aos Nossos
                Ambientes.
              </p>
              <br/><p>
                <b>ClassPay:</b> Funcionalidade disponível no Portal ClassApp que
                permite a gestão de receitas e cobranças realizadas aos responsáveis
                pelos alunos das Instituições de Ensino, referentes às despesas avulsas
                e esporádicas; além de possibilitar o recebimento destes pagamentos
                pelas Instituições de Ensino.
              </p>
              <br/><p>
                <b>Conta de Acesso:</b> Credencial de um Usuário que permite acesso à
                área restrita e às funcionalidades exclusivas existentes em Nossos
                Ambientes, definida pela combinação de <i>e-mail</i> ou número de
                celular e senha.
              </p>
              <br/><p>
                <b>Instituições de Ensino:</b> Instituições de ensino clientes, que
                contratam nossos serviços para utilização por seus funcionários,
                representantes, terceiros, alunos e responsáveis por alunos.
              </p>
              <br/><p>
                <b>Layout</b><b>:</b> Conjunto compreendido entre aparência, <i>design</i> e fluxos de Nossos Ambientes.
              </p>
              <br/><p>
                <b>Link</b><b>:</b> Terminologia para endereço de <i>internet</i>.
              </p>
              <br/><p>
                <b>Login</b><b>:</b> Processo para acessar um sistema informático
                restrito feito através da autenticação ou identificação do Usuário,
                usando credenciais previamente cadastradas no sistema.
              </p>
              <br/><p>
                <b>Matrículas e Financeiro:</b> Módulo adicional, o qual possibilita que
                as Instituições de Ensino realizem campanhas de matrícula, através do
                aceite de contrato pelos responsáveis dos alunos, permitindo o
                recebimento de valores pré-determinados, de forma automática, pelas
                Instituições de Ensino.
              </p>
              <br/><p>
                <b>Portal ClassApp:</b> Designa a área logada constante em nosso site
                institucional, disponível através do endereço eletrônico
                &lt;www.classapp.com.br&gt;.
              </p>
              <br/><p>
                <b>Usuários ou Você:</b> Pessoas físicas, autorizadas pelas Instituições
                de Ensino a utilizar Nossos Ambientes, através do perfis de acesso como
                Administrador/Gerenciador de Sistema.
              </p>
            
              <br/><p><b>FUNCIONALIDADES DISPONÍVEIS EM NOSSOS AMBIENTES</b></p>
            
              <br/><p>
                <b>Restrição de acesso.</b> As funcionalidades disponíveis em Nossos
                Ambientes para Usuários com perfis de Administrador de Sistema são
                destinadas exclusivamente a pessoas físicas maiores de 18 (dezoito)
                anos, indicadas exclusivamente pelas Instituições de Ensino.
              </p>
            
              <br/><p>
                <b>Nós</b> podemos recusar, não aprovar ou cancelar a criação de seu
                cadastro caso <b>Você</b> se utilize de dados incorretos ou falsos. O
                mesmo será aplicável durante a utilização de Nossos Ambientes, caso seja
                verificado seu uso de forma fraudulenta, bem como que tente ou viole
                estes Termos, ou quaisquer legislações vigentes.
              </p>
              <br/><p>
                Não obstante, caso seja verificada por <b>Nós</b> a existência de
                atividade suspeita ou transação que apresente considerável risco de
                fraude, <b>Nós</b> poderemos a qualquer tempo e a nosso exclusivo
                critério, solicitar informações adicionais a <b>Você</b>, sendo que,
                qualquer erro ou atraso no envio destas informações que gere prejuízo ou
                dano de qualquer natureza, será de sua inteira responsabilidade.
              </p>
            
              <br/><p>
                <b>Elegibilidade.</b> <b>Nós</b> podemos alterar os critérios de
                elegibilidade de acesso e utilização de Nossos Ambientes a qualquer
                momento e ao nosso critério, sem a necessidade de comunicação ou aviso
                prévio a <b>Você</b> ou a qualquer terceiro, sendo de extrema
                importância que <b>Você</b>, ao fazer uso das funcionalidades existentes
                em Nossos Ambientes, sempre verifique as regras vigentes.
              </p>
              <br/><p>
                <b>E-mails</b> <b>.</b> Ao fornecer seus dados para criação de uma Conta
                de Acesso em Nossos Ambientes, <b>Você</b> está ciente que, nos termos
                do previsto na Política de Privacidade e Tratamento de Dados, poderá
                receber, no <i>e-mail</i> e/ou telefone informados, mensagens com avisos
                e conteúdos publicitários enviados por <b>Nós</b>. Lembrando que,
                através dos Canais de Atendimento, a qualquer tempo, <b>Você</b> terá
                sempre a opção de suspender o recebimento destas comunicações, desde que
                não prejudique o contato imprescindível à resolução da solicitação
                realizada por <b>Você</b> em Nossos Ambientes.
              </p>
              <br/><p>
                <b>Apresentação de Nossos Ambientes.</b> Nossos Ambientes e as
                funcionalidades neles existentes são apresentadas a <b>Você</b> na
                maneira como estão disponíveis no momento do acesso, mas podem passar
                por constantes aprimoramentos e atualizações sem a necessidade de prévio
                aviso.
              </p>
            
              <br/><p>
                Neste caso, <b>Nós</b> nos comprometemos a preservar as funcionalidades
                de Nossos Ambientes com esforços razoáveis, utilizando <i>layout</i> que
                respeita a usabilidade e navegabilidade, sempre que possível, bem como a
                exibir as funcionalidades de maneira clara, completa, precisa e
                suficiente de modo que exista uma correta percepção do que está sendo
                praticado e proteger, por meio do estado da técnica disponível, os dados
                coletados pelas funcionalidades disponibilizadas, garantindo seu sigilo,
                de modo que estes sejam acessados apenas por <b>Nós</b>, por <b>Você</b> e demais usuários que você autorizar.
              </p>
            
              <br/><p><b>ACESSO AOS NOSSOS AMBIENTES</b></p>
            
              <br/><p>
                <b>Informações Necessárias.</b> Para utilizar as funcionalidades
                disponíveis em Nossos Ambientes, <b>Você</b> deverá realizar a criação
                de sua Conta de Acesso, onde serão solicitadas informações que deverão
                ser prestadas unicamente por <b>Você</b>.
              </p>
              <br/><p>
                Será necessário que <b>Você</b> informe: (i) seu endereço de <i>e-mail</i> e código de acesso enviado para sua caixa de entrada; ou
                (ii) seu número de celular e código de acesso enviado via SMS. Este
                código de acesso será gerado a cada vez que <b>Você</b> precisar acessar
                Nossos Ambientes e não possuir uma senha cadastrada.
              </p>
            
              <br/><p>
                Após seu primeiro acesso em Nossos Ambientes, será possível prosseguir
                com a criação de uma senha de acesso, a fim de garantir maior segurança
                aos seus dados.
              </p>
              <br/><p>
                Posteriormente ao seu acesso, será disponibilizada a <b>Você</b> a sua
                área logada, na qual <b>Você</b> poderá incluir uma foto de perfil de
                sua escolha.
              </p>
            
              <br/><p>
                <b>Validação.</b> As validações dos acessos realizados por <b>Você</b> são importantes para que <b>Nós</b> possamos manter a
                autenticidade da sua Conta de Acesso, prevenindo que esta seja utilizada
                por terceiros mal-intencionados, além de ser uma forma segura que
                permite a alteração de suas informações, acesso aos dados, utilização
                das funcionalidades, entre outros atos necessários no âmbito da
                navegação de Nossos Ambientes.
              </p>
            
              <br/><p><b>USO DE NOSSOS AMBIENTES POR VOCÊ</b></p>
            
              <br/><p>
                <b>Sigilo dos dados do cadastro.</b> <b>Você</b> deverá manter o sigilo
                dos dados de sua Conta de Acesso em relação a terceiros e utilizá-los de
                modo individual e intransferível, não disponibilizando, divulgando ou
                compartilhando sua senha ou qualquer mecanismo de autenticação com quem
                quer que seja. Caso suspeite que a confidencialidade de sua senha foi
                violada, <b>Você</b> deverá proceder com sua troca ou atualização o mais
                rápido possível, além de nos comunicar através dos Canais de
                Atendimento, para que possam ser tomadas as devidas providências, quando
                aplicável.
              </p>
              <br/><p>
                <b>Você</b> está ciente e reconhece que todos os acessos e operações
                realizadas em Nossos Ambientes após seu acesso bem-sucedido são
                interpretados como sendo de sua responsabilidade, de forma
                incontestável, inclusive aqueles derivados de uso indevido ou de
                divulgação deste para terceiros.
              </p>
              <br/><p>
                <b>Acesso aos Nossos Ambientes.</b> Para acesso aos Nossos Ambientes, <b>Você</b> deve utilizar-se da <i>internet</i> e:
              </p>
            
              <br/><p>
                Efetuar o acesso ao <i>link </i> <a
                  href="https://www.google.com/url?q=http://www.classapp.com.br/&amp;sa=D&amp;source=editors&amp;ust=1733780022861984&amp;usg=AOvVaw0kDmvjTt5Iib_fupSzO25g">www.classapp.com.br</a>,
                para interagir no Portal ClassApp; ou
              </p>
              <br/><p>
                Realizar o <i>download</i>, através da <i>App Store </i> ou <i>Google Play</i>, ao seu dispositivo móvel, do Aplicativo ClassApp.
              </p>
            
              <br/><p>
                Não sendo permitido o acesso por nenhum outro meio diverso não
                autorizado por <b>Nós</b>. <b>Funcionalidades de Nossos Ambientes.</b> Após a realização de sua identificação e
                autenticação através do <i>Login</i>, <b>Você</b> poderá utilizar Nossos Ambientes, onde poderá
                encontrar e ter acesso a diversas funcionalidades e conteúdos
                informativos criados para <b>Você</b>.
              </p>
            
              <br/><p>
                <b>Funcionalidades disponíveis no Aplicativo ClassApp.</b> As
                funcionalidades disponíveis no Aplicativo ClassApp têm, por objetivo,
                permitir que <b>Você</b>: (i) envie mensagens aos demais usuários,
                podendo esta conter formulários, cobranças de valores, enquetes,
                compromissos, relatórios, áudios, arquivos e demais formatos de mídias;
                (ii) efetue postagens e visualize aquelas realizadas pelos demais
                usuários através da aba “Momentos”; (iii) acesse o calendário e crie
                eventos; (iv) pesquise o histórico de conversas realizadas entre <b>Você</b> e demais usuários; (v) efetue o acompanhamento do <i>status</i> de utilização de nossos serviços pela Instituição de
                Ensino a qual <b>Você</b> está vinculado, através da aba “<i>Dashboard</i>”; e (vi) remova a Conta de Acesso vinculada à <b>Você</b>.
              </p>
              <br/><p>
                <b>Funcionalidades disponíveis no Portal ClassApp.</b> Além das opções
                descritas no item 4.4.1. acima, <b>Você</b> também encontrará
                disponíveis no Portal ClassApp as seguintes funcionalidades: (i) edição
                de suas informações de perfil; (ii) gestão de Contas de Acesso de
                alunos, responsáveis e funcionários da Instituição de Ensino, podendo
                criar e excluir tais Contas, bem como editar suas permissões; (iii)
                gestão de grupos e canais de comunicação relevantes à Instituição de
                Ensino; (iv) visualização de todo o histórico de conversas realizadas
                entre funcionários e alunos e/ou responsáveis; (v) criação e gerência de
                formulários e relatórios; (vi) utilização da ferramenta ClassPay para
                gestão de receitas e cobranças realizadas pela Instituição de Ensino;
                (vii) visualização e gestão de integrações de sistemas realizadas,
                solicitadas pela Instituição de Ensino a <b>Nós</b>; (viii) gerência de <i>links</i> disponibilizados aos usuários; (ix) configuração do perfil
                pertencente à Instituição de Ensino, possibilitando sua atualização bem
                como a personalização das permissões concedidas às categorias de
                usuários vinculados a esta; e (x) acesso ao módulo adicional Matrículas
                e Financeiro, quando contratado pela Instituição de Ensino a qual <b>Você</b> pertence, para realização de campanhas de matrícula e
                cobranças de valores automáticos, de acordo com as configurações
                determinadas por <b>Você</b>.
              </p>
              <br/><p>
                Para utilização da funcionalidade de compartilhamento de mídias, <b>Nós</b> poderemos solicitar acesso ao seu áudio, câmera ou galeria de
                imagens. Caso não permita o acesso, o serviço não poderá ser executado.
              </p>
              <br/><p>
                Para utilização da funcionalidade ClassPay, <b>Você</b> deverá, através
                da aba “Bancos e Saques”, inserir determinados dados da Instituição de
                Ensino, como: (i) razão social ou nome fantasia; (ii) contato financeiro
                responsável; (iii) dados financeiros, tais como: banco, agência e número
                da conta bancária; (iv) dados do titular da conta bancária cadastrada
                (CPF ou CNPJ, nome e endereço). Ainda, <b>Você</b> deverá configurar o
                modelo de absorção de taxas que pretende adotar, bem como o formato de
                saques (automático ou manual).
              </p>
            
              <br/><p>
                <b>Notificações.</b> <b>Você</b>, quando logado em Nossos Ambientes,
                receberá notificações a respeito de novas atividades vinculadas à sua
                Conta de Acesso.
              </p>
              <br/><p>
                <b>Integridade das informações.</b> <b>Você</b> é o único responsável
                pela integridade e veracidade das informações inseridas por <b>Você</b> em Nossos Ambientes, respondendo civil e criminalmente pelos
                efeitos advindos de uso irregular de informações de terceiros ou 
                informações falsas.
              </p>
            
              <br/><p>
                <b>Você</b> compromete-se a, sempre que houver alteração de seus dados,
                a nos informar, através da área logada em Nossos Ambientes ou dos Canais
                de Atendimento, para fins de atualização.
              </p>
            
              <br/><p><b>CONTEÚDO, DADOS SUBMETIDOS E PROPRIEDADE INTELECTUAL</b></p>
            
              <br/><p>
                <b>Conteúdo Informativo.</b> Exceto se estabelecido em contrário por
                estes Termos, todas as informações, notícias, imagens, marcas e
                informações de mercado disponibilizados por <b>Nós</b> em Nossos
                Ambientes, em qualquer idioma ou forma, é de nossa exclusiva propriedade
                ou licença (“Conteúdo Informativo”). O uso de Nossos Ambientes por <b>Você</b> não gera qualquer presunção de transferência dessa
                propriedade ou licença. Por essa razão, <b>Você</b> não deverá
                comercializar, no todo ou em parte, o Conteúdo Informativo por quaisquer
                meios, onerosamente ou não, sabendo que se o fizer poderá ser penalizado
                civil e penalmente por <b>Nós</b> e/ou por qualquer terceiro titular do
                Conteúdo Informativo.
              </p>
            
              <br/><p>
                Adicionalmente ao disposto acima, destacamos que as funcionalidades que
                compõe Nossos Ambientes são oferecidas na modalidade de prestação de
                serviços e licenciamento de <i>software</i>, não conferindo a <b>Você</b> nenhum direito sobre nosso <i>software</i> ou sobre suas
                estruturas de informática.
              </p>
              <br/><p>
                A eventual remoção, bloqueio ou suspensão de qualquer Conteúdo
                Informativo ou funcionalidade de Nossos Ambientes em decorrência de
                alguma reclamação deverá ser sempre compreendida como demonstração de
                boa-fé e intenção de solução amigável de conflitos, jamais como
                reconhecimento de culpa ou de qualquer infração por <b>Nós</b> a direito
                de terceiro.
              </p>
            
              <br/><p>
                <b>Dados e Informações Compartilhados.</b> <b>Você</b> é e continuará
                sendo o titular e proprietário de todos os dados e informações
                submetidos e/ou compartilhados em Nossos Ambientes por <b>Você</b> (“Dados e Informações”), bem como será o exclusivo
                responsável por eles.
              </p>
            
              <br/><p>
                Os Dados e Informações não constituirão nossa opinião, tampouco
                declaração de concordância com o que houver sido submetido e/ou
                compartilhado em Nossos Ambientes.
              </p>
              <br/><p>
                Todos os Dados e Informações submetidos e/ou compartilhados serão
                mantidos dentro de Nossos Ambientes, sendo vedado seu compartilhamento
                pelos demais usuários sem autorização.
              </p>
            
              <br/><p>
                <b>Legalidade dos Dados e Informações.</b> <b>Nós</b> não nos
                obrigaremos a processar ou tratar quaisquer dos Dados e Informações se
                houver razões para crer que tal processamento ou tratamento possa nos
                imputar qualquer infração de qualquer lei aplicável ou que Nossos
                Ambientes estejam sendo utilizados, sob nosso critério exclusivo, para
                quaisquer fins ilegais, ilícitos ou contrários à moralidade.
              </p>
              <br/><p>
                <b>Ausência de garantias quanto aos Dados e Informações e ao Conteúdo
                  Informativo.</b> <b>Nós</b> não garantimos a qualidade, coerência, integridade, forma,
                atualização ou fonte do Conteúdo Informativo ou dos Dados e Informações,
                pois não controlamos, verificamos ou fazemos juízo do Conteúdo
                Informativo, bem como dos Dados e Informações. <b>Nós</b> não somos
                responsáveis, portanto, por quaisquer inveracidade, desatualização ou
                erro do Conteúdo Informativo ou dos Dados e Informações, bem como não
                somos responsáveis por quaisquer danos oriundos do Conteúdo Informativo
                e dos Dados e Informações, a qualquer título.
              </p>
            
              <br/><p>
                <b>Você</b> fica ciente e concorda que <b>Nós</b> não possuímos qualquer
                obrigação real ou presumida de publicar qualquer Conteúdo Informativo ou
                Dados e Informações, bem como poderemos remover tal Conteúdo Informativo
                ou Dados e Informações com ou sem aviso prévio.
              </p>
              <br/><p>
                Adicionalmente ao disposto na cláusula acima, <b>Nós</b> nos reservamos
                ao direito de não publicar ou excluir Dados e Informações que:
              </p>
              <br/><p>1.  Ofendam a honra, imagem, dignidade e reputação de terceiros;</p>
              <br/><p>
                2.  Possuam conteúdos ligados à pornografia, pedofilia e matérias de cunho
                sexual;
              </p>
              <br/><p>
                3.  Contenham conteúdos relacionados à racismo, discriminação de qualquer
                natureza, práticas de <i>bullying</i>, <i>stalking</i> ou qualquer outra
                espécie de constrangimento ilegal ou assédio;
              </p>
              <br/><p>
                4.  Representem manifesta violação a direito autoral ou direito de imagem;
              </p>
              <br/><p>
                5.  Instiguem ou façam apologia à prática de crimes de qualquer natureza;
              </p>
              <br/><p>
                6.  Incentivem a prática de atos contrários à lei, à ordem pública e aos
                bons costumes;
              </p>
              <br/><p>
                7.  Disseminem a ideia de uso da violência, ódio ou qualquer outra forma de
                agressão ao bem-estar físico de alguém;
              </p>
              <br/><p>
                8.  Objetivem a realização de atividade comercial similar à nossa ou
                angariação de recursos por publicidade de terceiros, desviando-se da
                real intenção de nossos serviços; e
              </p>
              <br/><p>9.  Demonstrem erros ou suspeitas de equívocos.</p>            
              <br/><p>
                <b>Armazenamento.</b> <b>Nós</b> não prestamos e os Nossos Ambientes não
                constituem serviço de armazenamento, portanto <b>Nós</b> não possuímos a
                obrigação de armazenar, reter ou fornecer à <b>Você</b> ou a quaisquer
                terceiros cópia dos Dados e Informações ou qualquer Conteúdo
                Informativo.
              </p>
            
              <br/><p>
                Não obstante, ressaltamos que é de sua inteira responsabilidade a
                realização de <i>backup</i> dos Dados e Informações relevantes para <b>Você</b>.
              </p>
            
              <br/><p>
                <b>Não mineração.</b> É vedada a utilização de <i>softwares</i> de
                mineração de dados de Nossos Ambientes, de qualquer tipo ou espécie,
                além de outro aqui não tipificado que atue de modo similar.
              </p>
              <br/><p>
                <b>Indenização.</b> Na incidência de danos a <b>Nós</b> ou a terceiros,
                o responsável se compromete a arcar com todas as obrigações de indenizar
                o sujeito lesado, inclusive aqueles cuja origem for de atos praticados
                por meio de seu acesso aos Nossos Ambientes, assumindo o polo passivo de
                ação judicial ou procedimento administrativo e requerendo nossa
                exclusão, devendo arcar totalmente com as despesas e custas processuais
                atinentes, deixando-nos livre de prejuízos e ônus.
              </p>
              <br/><p>
                <b>Integridade de Nossos Ambientes.</b> <b>Você</b> compromete-se a não
                acessar áreas de programação de Nossos Ambientes, seu banco de dados,
                códigos fonte ou qualquer outro conjunto de dados disponíveis nestes
                ambientes, bem como não realizar ou permitir engenharia reversa, nem
                traduzir, decompilar, copiar, modificar, reproduzir, alugar,
                sublicenciar, publicar, divulgar, transmitir, emprestar, distribuir ou,
                de outra maneira, dispor inapropriadamente das funcionalidades de Nossos
                Ambientes.
              </p>
            
              <br/><p><b>ISENÇÃO E LIMITAÇÕES DE RESPONSABILIDADE</b></p>
            
              <br/><p>
                <b>Disponibilidade de Nossos Ambientes.</b> <b>Nós</b> envidamos
                esforços para a manutenção da disponibilidade contínua e permanente de
                Nossos Ambientes. No entanto, pode ocorrer, eventualmente, alguma
                indisponibilidade temporária decorrente de manutenção necessária ou
                mesmo gerada por motivo de força maior, como desastres naturais, falhas
                nos sistemas de comunicação e acesso à <i>internet</i>, ataques
                cibernético invasivos, ou quaisquer fatos de terceiro que fogem da
                esfera de nossa vigilância e responsabilidade.
              </p>
            
              <br/><p>
                Se isso ocorrer, <b>Nós</b> faremos o que estiver ao nosso alcance para
                restabelecer o acesso aos Nossos Ambientes o mais breve possível, dentro
                das limitações técnicas de nossos serviços e serviços de terceiros, dos
                quais dependemos para ficar <i>online</i>. Por essa razão, <b>Você</b> fica ciente que não poderá pleitear indenização ou reparação
                de danos no caso de Nossos Ambientes permanecerem fora do ar,
                independente da motivação.
              </p>
              <br/><p>
                Eventuais procedimentos de manutenção que acarretem a indisponibilidade
                de Nossos Ambientes por longos períodos serão informados por meio de
                nossos canais oficiais.
              </p>
              <br/><p>
                Sem prejuízo de outras condições destes Termos, <b>Nós</b> não nos
                responsabilizaremos:
              </p>
              <br/><p>
                1.  Por quaisquer problemas, <i>bugs</i>, <i>glitches</i> ou funcionamentos
                indevidos que ocorrerem nos seus dispositivos e equipamentos;
              </p>
              <br/><p>
                2.  Por qualquer dano direto ou indireto ocasionado por eventos de
                terceiros, a exemplo, mas não se limitando a ataque de <i>hackers</i>,
                falhas no sistema, no servidor ou na conexão à <i>internet</i>,
                inclusive por ações de <i>softwares</i> que possam, de algum modo,
                danificar ativos físicos ou lógicos em decorrência do acesso, utilização
                ou navegação em Nossos Ambientes, bem como a transferência de dados,
                arquivos, imagens, textos, áudios ou vídeos contidos em Nossos
                Ambientes;
              </p>
              <br/><p>
                3.  Pela navegação em quaisquer <i>links </i> externos disponibilizados em
                Nossos Ambientes, sendo seu dever, a leitura dos Termos e Condições de
                Uso e da Política de Privacidade do recurso acessado e agir conforme o
                determinado;
              </p>
              <br/><p>
                4.  Por verificar, controlar, aprovar ou garantir a adequação ou exatidão
                das informações ou dados disponibilizados em tais <i>links</i>, não
                sendo, portanto, responsáveis por prejuízos, perdas ou danos ocorridos
                pela visita de tais <i>websites</i>, cabendo ao interessado verificar a
                confiabilidade das informações e dados ali exibidos antes de tomar
                alguma decisão ou praticar algum ato; e
              </p>
              <br/><p>
                5.  Por eventuais vazamentos de Dados e Informações não autorizados
                realizados por qualquer usuário.
              </p>
              <br/><p>
                Os Dados e Informações submetidos e/ou compartilhados em Nossos
                Ambientes são armazenados em servidores seguros, de forma criptografada,
                com a utilização de medidas de segurança da informação constantemente
                atualizadas. Os Dados e Informações serão mantidos como confidenciais e
                serão adotadas todas as medidas possíveis contra perda, roubo, uso
                indevido, alteração e acesso não autorizado. Neste sentindo, destacados
                que <b>Nós</b> não nos responsabilizaremos por eventuais prejuízos que
                sejam decorrentes da divulgação de tais Dados e Informações por parte de
                terceiros que utilizem a rede pública ou a <i>internet</i>, subvertendo
                os sistemas de segurança para acessar as informações do Usuário.
              </p>
            
              <br/><p><b>RECOMENDAÇÕES DE SEGURANÇA</b></p>
            
              <br/><p>
                <b>Recomendações gerais.</b> Para acessar Nossos Ambientes de forma
                segura e utilizar nossas funcionalidades de forma integral, é de inteira
                responsabilidade do Usuário dispor de dispositivo e equipamentos
                compatíveis, serviço de conexão à <i>internet</i> com antivírus e <i>firewall</i> habilitados e <i>softwares</i> devidamente atualizados –
                tais como navegadores e sistemas operacionais de dispositivos móveis –
                além da adoção de medidas de segurança mínima, como a adoção de uma
                senha forte para <i>Login</i> em sua Conta de Acesso.
              </p>
            
              <br/><p>Entende-se por senha forte aquela que:</p>
              <br/><p>
                1.  Possui ao menos 8 (oito) caracteres, sendo 1 (uma) letra maiúscula, 1
                (uma) letra minúscula e 1 (um) número, símbolo ou caractere especial;
              </p>
              <br/><p>
                2.  Não representa sequências numéricas relacionadas às informações pessoais
                ou de fácil dedução, tais como elementos do próprio nome, datas
                comemorativas ou repetição de caracteres iguais; e
              </p>
              <br/><p>
                3.  Não é reutilizada em aplicativos, portais e plataformas de terceiros.
              </p>
              <br/><p>
                É de sua responsabilidade deixar seus sistemas <i>AntiSpam</i>, filtros
                similares ou configurações de redirecionamento de mensagens ou
                notificações ajustados de modo que não interfiram no recebimento dos
                comunicados e materiais enviados pelos demais usuários através do uso de
                nossos serviços.
              </p>
              <br/><p>
                <b>Acesso ao Portal ClassApp.</b> Sempre que acessar o Portal ClassApp,
                verifique se de fato está no ambiente disponibilizado por <b>Nós</b>,
                ações de <i>hackers</i> podem em uma tentativa de roubar seus dados
                replicarem uma cópia do Portal ClassApp ou áreas deste.
              </p>
            
              <br/><p>
                Para verificar a integridade e veracidade do Portal ClassApp, clique no
                cadeado presente na barra de endereço do navegador, verificando os
                certificados presentes e informados.
              </p>
              <br/><p>
                Caso o navegador não apresente os certificados e/ou retorne com a
                mensagem de que o acesso não é seguro, interrompa imediatamente o uso do
                Portal ClassApp e nos comunique para que as devidas providências sejam
                tomadas.
              </p>
            
              <br/><p>
                <b>Acesso não autorizado.</b> <b>Nós</b> não seremos responsáveis por
                eventual acesso de terceiros não autorizados a sua Conta de Acesso,
                cabendo somente à <b>Você</b> a responsabilidade pela guarda de <i>login</i> e senha em local seguro, assim como o não compartilhamento
                destas informações com terceiros.
              </p>
            
              <br/><p>
                Não nos caberá também a responsabilidade pelo acesso não autorizado
                proveniente de ataques realizados por <i>hackers</i> ou devido a ações
                de vírus que venham a acessar as suas informações e seu cadastro.
              </p>
              <br/><p>
                Caso <b>Você</b> constate ou desconfie que seu cadastro está sendo
                utilizado por terceiros não autorizados ou que tenha sido vítima de
                ataque de <i>hackers</i> ou de programas e/ou arquivos maliciosos
                (vírus), entre em contato imediatamente conosco através dos Canais de
                Atendimento, para que possam ser tomadas as devidas providências.
              </p>
            
              <br/><p><b>PENALIDADES</b></p>
            
              <br/><p>
                <b>Penalidades.</b> O descumprimento de qualquer cláusula destes Termos,
                poderá gerar: (i) suspensão temporária de Nossos Ambientes para seu <i>Login</i> e consequente impossibilidade de usufruir de Nossos
                Ambientes; (ii) bloqueio e exclusão de sua Conta de Acesso e/ou (iii)
                responsabilização nas esferas cível e penal.
              </p>
            
              <br/><p>
                <b>Você</b> reconhece desde já e compromete-se, durante a utilização de
                Nossos Ambientes, a não realizar quaisquer ações que atentem contra a
                moralidade, a ética e boa-fé, obrigando-se a em nenhuma hipótese
                divulgar, instigar ou auxiliar a prática de atos ilícitos ou
                fraudulentos, devendo combatê-los sempre que tomar conhecimento sob pena
                das previsões da cláusula 8.1 acima.
              </p>
            
              <br/><p><b>OUTRAS DISPOSIÇÕES</b></p>
            
              <br/><p>
                <b>Canais de Atendimento.</b> <b>Você</b> poderá utilizar os Canais de
                Atendimento disponibilizados em Nossos Ambientes toda vez que presenciar
                ou verificar conteúdo impróprio em Nossos Ambientes, seja ele
                notadamente ilícito ou contrário às regras de uso estipuladas nestes
                Termos.
              </p>
            
              <br/><p>
                Eventual denúncia realizada em nossos Canais de Atendimento será
                sigilosa e preservará a sua identidade.
              </p>
              <br/><p>
                Buscamos agir de forma diligente e imediata nos casos de denúncias
                feitas por <b>Você</b> relacionadas à conteúdos que violem os princípios
                da ética, moral e bons costumes, que sejam ilícitos e que envolvam,
                especialmente, menores de idade.
              </p>
            
              <br/><p>
                <b>Atualização dos Termos.</b> Estes Termos estão sujeitos a constante
                melhoria e aprimoramento. Assim, <b>Nós</b> nos reservamos ao direito de
                modificá-los a qualquer momento, conforme sua finalidade ou
                conveniência, tal qual para adequação e conformidade legal de disposição
                de lei ou norma que tenha força jurídica equivalente, incluindo de
                órgãos reguladores, ou seja, <b>Você</b> deverá verificá-los sempre que
                efetuar o acesso em Nossos Ambientes.
              </p>
            
              <br/><p>
                Ocorrendo atualizações nestes Termos, <b>Nós</b> notificaremos <b>Você</b> pelas ferramentas disponíveis em Nossos Ambientes ou pelos
                meios de contato fornecidos por <b>Você</b>.
              </p>
              <br/><p>
                Ao navegar por Nossos Ambientes e utilizar suas funcionalidades, <b>Você</b> aceita guiar-se pelos Termos que se encontram vigentes na
                data de acesso. Por isso, é recomendável que <b>Você</b> se atualize das
                condições vigentes.
              </p>
            
              <br/><p>
                <b>Novação e renúncia.</b> A tolerância do eventual descumprimento de
                quaisquer condições destes Termos não constituirá renúncia ou novação
                nem nos impedirá de exigir estas condições a qualquer tempo.
              </p>
              <br/><p>
                <b>Nulidade.</b> Se alguma disposição destes Termos for julgada
                inaplicável ou sem efeito, o restante continuará a viger normalmente.
              </p>
              <br/><p>
                <b>Comunicação.</b> <b>Você</b> reconhece que toda comunicação realizada
                por <i>e-mail</i> ou telefone (ao endereço de <i>e-mail</i> e número de
                telefone/celular informados no seu cadastro) é válida, eficaz e
                suficiente para a divulgação de qualquer assunto que se refira aos
                Nossos Ambientes.
              </p>
              <br/><p>
                <b>Data e hora.</b> Nossos Ambientes possuem como base a data e horário
                oficial de Brasília (UTC/GMT -03:00).
              </p>
              <br/><p>
                <b>Lei aplicável e foro.</b> Essa Política será interpretada segundo a
                legislação brasileira, no idioma português, sendo eleito o foro da
                Comarca de Limeira, Estado de São Paulo, para dirimir qualquer
                controvérsia que envolva este documento, salvo ressalva específica de
                competência pessoal, territorial ou funcional pela legislação aplicável.
                <hr />
              </p>
            </div>
          </section>
        }

        {
          (entityTypes.includes('student') || entityTypes.includes('noentity')) &&
          <section>
            {!this.props.noHeader && <h2 id="policy-student" className="avoid-header-overlapping" style={{ marginBottom: '38px' }}>POLÍTICA DE PRIVACIDADE E TRATAMENTO DE DADOS DA CLASSAPP PARA ALUNOS E RESPONSÁVEIS (“POLÍTICA”)</h2>}
            <p>Atualizada em 06 de dezembro de 2024.</p>
            <br/><br/><p><strong>INTRODUÇÃO</strong></p>
            <br/><p>A presente Política tem por finalidade demonstrar o compromisso da <strong>CLASSAPP SISTEMAS LTDA.</strong>, (“<strong>Nós</strong>”) com a privacidade e a proteção dos seus Dados. Estamos localizados na RUA GUARARAPES, Nº 00136, , KUHL -VL. - LIMEIRA/SP - CEP: 13480-000 e somos registrados no CNPJ sob o n.º 21.560.411/0001-01. Esta Política visa garantir, de forma clara e transparente, de acordo com as leis em vigor, em especial a Lei n.º 13.709/2018 (“Lei Geral de Proteção de Dados Pessoais”).</p>
            <br/><p>Esta Política descreve as principais diretrizes sobre o tratamento dos seus Dados, em especial Dados Pessoais e Dados Pessoais Sensíveis, quando <strong>Você</strong> faz uso dos nossos serviços e funcionalidades, seja por meio da área logada disponível em nosso site institucional &lt;www.classapp.com.br&gt; (“Portal ClassApp”) ou por meio do acesso ao nosso aplicativo (“Aplicativo ClassApp”), em conjunto denominados de “Nossos Ambientes”.</p>
            <p><strong>Nós</strong> divulgamos esta Política para lhe dar transparência sobre as regras de privacidade aplicáveis, garantindo que o tratamento dos Dados Pessoais e Dados Pessoais Sensíveis servirá apenas para possibilitar a prestação de nossos serviços, observando-se todos os princípios previstos na legislação de proteção de dados aplicável. Caso tenha qualquer dúvida, estamos à disposição para ouvi-lo. Sua opinião é muito importante para <strong>Nós</strong>.</p>
            <br/><p>Para acessar e utilizar das funcionalidades ofertadas em Nossos Ambientes, <u><strong>Você deve</strong> estar plenamente ciente dos termos aqui estipulados e conferindo, assim, sua livre e expressa concordância com estes, incluindo a coleta dos Dados aqui mencionados, bem como seu tratamento para os fins abaixo especificados.</u></p>
            <br/><p>Estamos sempre buscando lhes oferecer serviços e funcionalidades com a maior eficiência possível, nos atualizando constantemente para isto. Por esse motivo, esta Política poderá sofrer ajustes a qualquer tempo, cabendo a <strong>Você</strong> verificá-la sempre que possível por meio do acesso aos Nossos Ambientes.</p>
            <br/><p><strong>NOTA ESPECIAL PARA PAIS E REPRESENTANTES LEGAIS</strong></p>
            <br/><p><strong>Nós </strong>realizamos o Tratamento de Dados Pessoais, inclusive Dados Pessoais Sensíveis, de menores de 18 (dezoito) anos em Nossos Ambientes.</p>
            <br/><p>Ainda que haja a coleta de consentimento dos pais e responsáveis legais pelas Instituições de Ensino para o Tratamento dos Dados, estes deverão supervisionar as atividades <em>online</em> de seus filhos e/ou tutelados, responsabilizando-se pelo uso devido e regular de Nossos Ambientes.</p>
            <p>As atividades de <u>adolescentes maiores de 16 anos e menores de 18 anos</u> devem ser assistidas pelos pais ou representantes legais.</p>
            <br/><p><strong>DEFINIÇÕES</strong></p>
            <br/><br/><p>Sem prejuízo de outras definições constantes nesta Política, as palavras e expressões abaixo indicadas, sempre que utilizadas pela primeira letra maiúscula, terão as seguintes definições:</p>
            <br/><p><strong>1.  Anonimização:</strong> Utilização de meios técnicos razoáveis e disponíveis no momento do Tratamento, por meio dos quais um Dado perde a possibilidade de associação, direta ou indireta, a um indivíduo.</p>
            <br/><p><strong>2.  Aplicativo ClassApp:</strong> Aplicativo disponibilizado aos alunos, pais, responsáveis legais, pedagógicos e financeiros, com a finalidade de proporcionar uma alternativa conveniente para que estes interajam com nossos conteúdos e utilizem as funcionalidades e serviços oferecidos, podendo ser baixado por meio do <em>App Store</em> e <em>Google Play</em>.</p>
            <br/><p><strong><em>3.  Cloud Computing</em>:</strong> Ou computação em nuvem, é a tecnologia de virtualização de serviços construída a partir da interligação de mais de um servidor por meio de uma rede de informação comum (p.ex. a <em>Internet</em>), com objetivo de reduzir custos e aumentar a disponibilidade dos serviços sustentados.</p>
            <br/><p><strong>4.  Conta de Acesso:</strong> Credencial de um Usuário que permite acesso à área restrita e às funcionalidades exclusivas existentes no Portal ClassApp e no Aplicativo ClassApp, definida pela combinação de <em>e-mail</em> ou número de celular e senha.</p>
            <br/><p><strong><em>5.  Cookies</em>:</strong> Pequenos arquivos digitais em formato de texto que são armazenados no seu dispositivo (computador, <em>smartphone</em>, <em>tablet</em>, etc.) pelo navegador de <em>internet</em> e que guardam informações relacionadas às suas preferências, como idioma preferido, localização, recorrência das suas sessões, e outras variáveis que os desenvolvedores consideram relevantes para tornar a sua experiência muito mais eficiente.</p>
            <br/><p><strong>6.  Dados:</strong> Quaisquer informações inseridas, tratadas ou transmitidas através dos Nossos Ambientes.</p>
            <br/><p><strong>7.  Dados Pessoais:</strong> Dados relacionados a pessoa natural identificada ou identificável.</p>
            <br/><p><strong>8.  Dados Pessoais Sensíveis:</strong> aqueles de origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.</p>
            <br/><p><strong>9.  Decisões unicamente automatizadas:</strong> Trata-se de decisões que afetam um usuário que foram programadas para funcionar automaticamente, sem a necessidade de uma operação humana, com base em Tratamento automatizado de Dados Pessoais.</p>
            <br/><p><strong>10.  Encarregado (<em>Data Protection Officer -DPO</em>):</strong> Pessoa indicada por <strong>Nós</strong> para atuar como canal de comunicação entre <strong>Nós</strong>, os titulares dos Dados Pessoais e a Autoridade Nacional de Proteção de Dados (ANPD).</p>
            <br/><p><strong>11.  <em>ID </em>de Sessão:</strong> Identificação da sessão de Usuários quando é efetuado o acesso em Nossos Ambientes.</p>
            <br/><p><strong>12.  Instituições de Ensino:</strong> Instituições de ensino clientes, que contratam nossos serviços para utilização por seus funcionários, representantes, terceiros, alunos e responsáveis por alunos.</p>
            <br/><p><strong>13.  <em>IP</em>:</strong> Abreviatura de <em>Internet Protocol</em>. É conjunto alfanumérico que identifica os dispositivos dos usuários na <em>Internet</em>.</p>
            <br/><p><strong>14.  <em>Link</em>:</strong> Terminologia para endereço de <em>internet.</em></p>
            <br/><p><strong>15.  <em>Log</em>:</strong> Registro de atividades realizadas pelos Usuários em Nossos Ambientes.</p>
            <br/><p><strong>16. <em>Login</em>:</strong> Processo para acessar um sistema informático restrito feito através da autenticação ou identificação do Usuário, usando credenciais previamente cadastradas no sistema.</p>
            <br/><p><strong>17.  Portal ClassApp:</strong> Designa a área logada constante em nosso site institucional, disponível através do endereço eletrônico &lt;www.classapp.com.br&gt;.</p>
            <br/><p><strong>18.  Tratamento:</strong> Toda operação realizada com Dados Pessoais e Dados Pessoais Sensíveis, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.</p>
            <br/><p><strong>19.  Usuário(s) ou Você:</strong> Pessoas físicas que utilizam Nossos Ambientes através de perfis de acessos como “alunos” ou “responsáveis”. São os alunos, pais, representantes legais, pedagógicos e financeiros que desejam experimentar nossos serviços através de uma simulação ou que foram previamente cadastrados em nosso sistema pelas Instituições de Ensino para utilização de nossos serviços e funcionalidades disponíveis.</p>
            <br/><p><strong>SOBRE DADOS QUE COLETAMOS</strong></p>
            <br/><p><strong>Como coletamos Dados?</strong> </p>
            <br/><p>Coletamos os Dados compartilhados por <strong>Você</strong> com as Instituições de Ensino para criação de sua Conta de Acesso de Usuário. Além disso, poderemos coletar Dados Pessoais e Dados Pessoais Sensíveis quando <strong>Você</strong> os submete ou interage em Nossos Ambientes e serviços, o que inclui:</p>
            <br/><br/><p><strong>1.  O que coletamos?</strong></p>
            <br/><p><strong>Dados cadastrais</strong></p>
            <br/><p><strong>ALUNO:</strong></p>
            <ul>
              <li><p>Nome completo, turma, <em>e-mail</em>, celular, data de nascimento, CPF e foto de perfil (opcional)</p></li>
            </ul>
            <br/><p><strong>RESPONSÁVEL<sup>1</sup>:</strong></p>
            <ul>
              <li><p>Nome completo, <em>e-mail</em>, celular, CPF e RG</p></li>
            </ul>
            <br/><sub><small>1 - Responsável legal, pedagógico e financeiro do aluno.</small></sub>
            <br/><br/><p><strong>Dados de mídias</strong></p>
            <ul>
              <li><p>Áudios, fotos e vídeos</p></li>
            </ul>
            <br/><p><strong>Dados de pagamento</strong></p>
            <ul>
              <li><p>Número do cartão de crédito e informações correlatas</p></li>
            </ul>
           
            <br/><p><strong>Dados de saúde (opcionais)</strong></p>
            <ul>
              <li><p>Suspeita de casos de COVID-19 no ambiente familiar</p></li>
              <li><p>Contato com pessoas infectadas</p></li>
              <li><p>Confirmação de contágio</p></li>
            </ul>
            <br/><p><strong>Dados de identificação digital</strong></p>
            <ul>
              <li>
                <p><em>ID</em> da Sessão</p>
              </li>
              <li>
                <p><em>Log</em> de <em>IP</em></p>
              </li>
              <li>
                <p>Endereço <em>IP</em> e Porta Lógica de Origem</p>
              </li>
              <li>
                <p>Informações de dispositivo (tipo do dispositivo, conexões de rede, nome do dispositivo, versão do sistema operacional e conexão de <em>internet</em> utilizada)</p>
              </li>
              <li>
                <p>Geolocalização</p>
              </li>
              <li>
                <p>Registros de data e horário de cada ação que <strong>Você</strong> realizar</p>
              </li>
              <li>
                <p>Quais telas <strong>Você</strong> acessou</p>
              </li>
              <li>
                <p><em>Cookies</em></p>
              </li>
            </ul>

            <br/><p><strong>2. Para que coletamos?</strong></p>
            <br/><p><strong>Dados cadastrais</strong></p>
            <ul>
              <li><p>Cadastrar, identificar e autenticar <strong>Você</strong>;</p></li>
              <li><p>Cumprir as obrigações decorrentes do uso dos nossos serviços e funcionalidades, inclusive para atendimento de disposições legais e regulatórias;</p></li>
              <li><p>Possibilitar a criação de uma Conta de Acesso para utilização de nossos serviços e funcionalidades, inclusive para realização de simulações em Nossos Ambientes;</p></li>
              <li><p>Atender adequadamente às suas solicitações e dúvidas;</p></li>
              <li><p>Aperfeiçoar o uso e a experiência interativa durante sua navegação em Nossos Ambientes;</p></li>
              <li><p>Promover nossos serviços, além de lhe informar sobre novidades, funcionalidades, conteúdos, notícias e demais informações relevantes para <strong>Você</strong>;</p></li>
              <li><p>Garantir a validade jurídica de assinaturas eletrônicas em contratos firmados com as Instituições de Ensino e proporcionar o processamento de operações financeiras solicitadas por <strong>Você</strong>;</p></li>
              <li><p>Proteger <strong>Você</strong> no que diz respeito à prevenção de fraudes e riscos associados, além do cumprimento de obrigações legais e regulatórias;</p></li>
              <li><p>Cadastro de ficha financeira, informações pedagógicas, fichas médicas, ocorrências e rotinas diárias do aluno;</p></li>
            </ul>
            <br/><p><strong>Dados de mídias</strong></p>
            <ul>
              <li><p>Possibilita a utilização por <strong><strong>Você</strong> </strong>da funcionalidade relativa ao compartilhamento de mídias em Nossos Ambientes;</p></li>
              <li><p>Compartilhamento de material visual para as redes sociais da escola;</p></li>
            </ul>
            <br/><p><strong>Dados de pagamento</strong></p>
            <ul>
              <li><p>Cumprir com as obrigações legais e as decorrentes de nossos serviços e funcionalidades de pagamentos disponíveis em Nossos Ambientes, compartilhando os Dados com a empresa terceira responsável em processar o pagamento;</p></li>
              <li><p>Proteção do crédito e procedimentos de cobrança;</p></li>
            </ul>
            <br/><p><strong>Dados de saúde (opcionais)</strong></p>
            <ul>
              <li><p>Permitir a utilização de nossos serviços opcionais relacionados ao monitoramento de saúde e bem-estar dos alunos, os quais visam evitar a disseminação de doenças contagiosas na comunidade escolar;</p></li>
              <li><p>Responder às pesquisas semanais de controle e acompanhamento enviadas pelas Instituições de Ensino;</p></li>
            </ul>
            <br/><p><strong>Dados de identificação digital</strong></p>
            <ul>
              <li><p>Identificar e autenticar <strong>Você</strong>;</p></li>
              <li><p>Efetuar estatísticas, estudos, pesquisas e levantamentos relacionados ao comportamento do Usuário em Nossos Ambientes, realizando tais operações de forma anonimizada;</p></li>
              <li><p>Garantir a validade jurídica das informações fornecidas por <strong>Você</strong> quando do preenchimento de enquetes e formulários criados pelas Instituições de Ensino e disponibilizados em Nossos Ambientes;</p></li>
              <li><p>Corrigir problemas de tecnologia;</p></li>
              <li><p>Proteger <strong>Você</strong> no que diz respeito à prevenção de fraudes e riscos associados, além do cumprimento de obrigações legais e regulatórias; e</p></li>
              <li><p>Cumprir com obrigações legais de manutenção de registros estabelecidas pelo Marco Civil da Internet - Lei 12.965/2014.</p></li>
            </ul>
            <br/><p>Para finalidade de prevenção a fraudes e garantia da autenticidade dos Dados fornecidos, <strong>Nós</strong> poderemos solicitar outros Dados Pessoais adicionais, não previstos na tabela acima, para confirmação das informações fornecidas pelos Usuários. Neste caso, <strong>Nós</strong> poderemos entrar em contato diretamente com <strong>Você</strong> para efetuar a requisição.</p>
            <br/><p><strong>Dados necessários.</strong> A prestação dos nossos serviços e funcionalidades disponíveis em Nossos Ambientes dependem diretamente de determinados Dados informados na tabela acima, em especial, dos Dados cadastrais para criação de sua Conta de Acesso. Caso <strong>Você</strong> opte por não fornecer alguns desses Dados, poderemos ficar impossibilitados de executar parte de nossos serviços e funcionalidades em Nossos Ambientes.</p>
            <br/><p><strong>Atualização e veracidade dos dados.</strong> <strong>Você</strong> é o único responsável pela precisão, veracidade ou falta dela em relação aos Dados que <strong>Você</strong> fornece ou pela sua desatualização. Fique atento pois é de sua responsabilidade garantir a exatidão ou mantê-los atualizados.</p>
            <br/><p>1.  Da mesma forma, <strong>Nós</strong> não somos obrigados a tratar quaisquer dos seus Dados se houver razões para crer que tal Tratamento possa nos imputar infração da legislação aplicável, ou se <strong>Você</strong> estiver utilizando Nossos Ambientes para quaisquer fins ilegais, ilícitos ou contrários à moralidade.</p>
            <br/><p><strong>2.  Base de Dados.</strong> A base de Dados, de propriedade das Instituições de Ensino, formada por meio da coleta de Dados, estará sob nossa responsabilidade, sendo que seu uso, acesso e compartilhamento, quando necessários, serão feitos dentro dos limites e propósitos dos negócios descritos nesta Política.</p>
            <br/><br/><p><strong>Tecnologias empregadas.</strong> <strong>Nós</strong> utilizamos a(s) seguinte(s) tecnologia(s):</p>
            <br/><p><em>1.  Cookies</em>, utilizados para melhorar a funcionalidade do Portal ClassApp. A qualquer tempo, <strong>Você</strong> pode bloquear o uso de <em>Cookies</em> através das configurações o seu navegador de <em>Internet</em>, hipótese em que algumas funcionalidades do Portal ClassApp poderão ser limitadas;</p>
            <br/><p><em>2.  Tags </em>de <em>Pixel</em>, possibilitam a coleta de informações (arquivos gravados em seu computador) referentes ao seu histórico de navegação, facilitam seu <em>login</em> (mediante armazenamento de suas credenciais) e promovem a interação entre <strong>Você</strong> e os serviços oferecidos por <strong>Nós</strong>. As <em>Tags</em> de <em>Pixel</em> também poderão ser desabilitadas mediante configurações das opções existentes em seu navegador de <em>Internet</em>;</p>
            <br/><p><em>3.  Log files</em>, registram atividades referentes ao uso de nosso Portal ClassApp, tais como acesso a outros <em>websites</em>, o tipo e a qualidade de conexão de <em>internet</em>, <em>hardwares</em> ou <em>softwares</em> utilizados e as funcionalidades de determinado serviço que tenha sido utilizado por <strong>Você</strong>. Os <em>log files</em> poderão ser utilizados para monitorar o tráfego no Portal ClassApp e solucionar problemas técnicos;</p>
            <br/><p><em>4.  Web beacons</em>, usados para rastrear anonimamente os seus padrões de uso <em>online</em>, a exemplo de <em>e-mails</em> abertos e <em>links</em> clicados por <strong>Você</strong>. As informações coletadas poderão identificar <em>websites</em>, serviços, produtos e funcionalidades populares, as quais serão utilizadas para personalizar nossos serviços para <strong>Você</strong>;</p>
            <br/><p><em>5.  Google Analytics</em>, para registro de dados de navegação não identificáveis para análise; e</p>
            <br/><p><em>6.  Adwords</em> e <em>Facebook</em>, para registro de eventos durante a navegação.</p>
            <br/><p><strong>7.  Nós</strong> também poderemos utilizar ferramentas fornecidas por empresas terceiras, as quais registrarão as atividades de interação do Usuário com Nossos Ambientes. O Tratamento destes Dados será realizado de forma anônima, com a finalidade de melhorar a experiência e conteúdo de Nossos Ambientes.</p>
            <br/><br/><p>Todas as tecnologias utilizadas respeitarão sempre a legislação vigente e os termos desta Política.</p>
            <br/><br/><p style={{ border: '1px solid black', padding: '1rem 1rem 1rem'}}><strong>Nós</strong> não utilizamos nenhum tipo de decisão unicamente automatizada que impacte <strong>Você</strong>.</p>
            <br/><br/><p><strong>COMO COMPARTILHAMOS DADOS E INFORMAÇÕES</strong></p>
            <br/><strong>Hipóteses de compartilhamento dos Dados.</strong> Os Dados coletados e as atividades registradas podem ser compartilhados, sempre respeitando o envio do mínimo de informações necessárias para atingir as finalidades:
            <br/><br/><p>1.  Com autoridades judiciais, administrativas ou governamentais competentes, sempre que houver determinação legal, requerimento, requisição ou ordem judicial;</p>
            <br/><p>2.  Com empresas parceiras e prestadores de serviços necessários à execução dos nossos serviços e funcionalidades, sempre exigindo de tais organizações o cumprimento das diretrizes de segurança e proteção de dados, conforme item 5.7. desta Política; e</p>
            <br/><p>3.  De forma automática, em caso de movimentações societárias, como fusão, aquisição e incorporação.</p>
            <br/><p><strong>Anonimização de Dados.</strong> Para as finalidades de pesquisas de inteligência de mercado, divulgação de dados à imprensa, realização de propagandas e outras atividades aplicáveis, os dados fornecidos por <strong>Você</strong> serão compartilhados de forma anonimizada, isto é, de forma que não possibilite a sua identificação.</p>
            <br/><br/><p><strong>COMO PROTEGEMOS SEUS DADOS E COMO VOCÊ TAMBÉM PODERÁ PROTEGÊ-LOS</strong></p>
            <br/><br/><p><strong>Medidas que tomamos.</strong> <strong>Nós</strong> empregamos os melhores esforços para manter a privacidade e a segurança das informações por meio da adoção de medidas de segurança técnicas, físicas e administrativas:</p>
            <br/><p><strong>1.  Medidas técnicas</strong>, como por exemplo transmissão de Dados Pessoais por meio de página de <em>internet </em>segura, armazenamento de Dados em meios eletrônicos que mantêm altos padrões de segurança, utilização de sistema cujo acesso é controlado;</p>
            <br/><p><strong>2.  Medidas físicas</strong>, tais como acesso restrito a pessoas autorizadas mantido em instalações que incluem, uso de ferramentas de segurança de mercado; e</p>
            <br/><p><strong>3.  Medidas administrativas</strong>, incluindo a adoção de Políticas e Normas de Segurança, treinamentos/conscientização de colaboradores, acordos de confidencialidade.</p>
            <br/><p><strong>Compartilhamento de senhas.</strong> <strong>Você</strong> também é responsável pelo sigilo de seus Dados Pessoais e deve ter sempre ciência de que o compartilhamento de senhas e dados de acesso viola esta Política e pode comprometer a segurança dos seus Dados e dos Nossos Ambientes.</p>
            <br/><p><strong>Cuidados que <strong>Você</strong> dever tomar.</strong> É muito importante que <strong>Você</strong> proteja seus Dados contra acesso não autorizado ao seu dispositivo, conta ou senha. Também é muito importante que <strong>Você</strong> saiba que <strong>Nós</strong> nunca enviaremos mensagens eletrônicas com anexos que possam ser executados (extensões:.exe,.com, entre outros) ou ainda <em>links</em> para eventuais <em>downloads</em>. Nossos contatos têm a finalidade de trazer informações sobre suas dúvidas e solicitações, bem como demais informações relevantes para <strong>Você</strong>.</p>
            <br/><p><strong>Acesso aos Dados Pessoais, proporcionalidade e relevância.</strong> Internamente, os Dados Pessoais coletados são acessados somente por profissionais devidamente autorizados, respeitando os princípios de proporcionalidade, necessidade e relevância para os objetivos do nosso negócio, além do compromisso de confidencialidade e preservação da sua privacidade disposto na presente Política. Profissionais e/ou pessoas autorizadas que se utilizarem indevidamente desses Dados, violando os termos desta Política, estarão sujeitos às penalidades previstas em nosso processo disciplinar, sem exclusão das demais penalidades legais aplicáveis.</p>
            <br/><p><strong>Segurança da informação.</strong> Todas as transações de pagamento, com cartão de crédito ou não, são executadas com a tecnologia SSL (<em>secure socket layer</em>), garantindo que todos os seus Dados não sejam ilicitamente divulgados. Além disso, essa tecnologia visa impedir que as informações sejam transmitidas ou acessadas por terceiros.</p>
            <br/><p><strong><em>Links </em>externos.</strong> Quando <strong>Você</strong> utilizar Nossos Ambientes, <strong>Você</strong> poderá ser conduzido, via <em>link</em> a outros portais ou plataformas, inclusive ambientes pertencentes a terceiros prestadores de serviços das Instituições de Ensino, como resultado da integração de Nossos Ambientes com ambientes de terceiros requisitada pelas Instituições de Ensino, podendo estes coletar suas informações e ter sua própria Política de Tratamento de Dados.</p>
            <br/><p>Caberá a <strong>Você</strong> ler a Políticas de Privacidade e Tratamento de Dados de tais portais ou plataformas fora de Nossos Ambientes, sendo de sua responsabilidade estar ciente do seu conteúdo. <strong>Nós</strong> não somos responsáveis pelas Políticas de Privacidade e Tratamento de Dados de terceiros e nem pelo conteúdo de quaisquer <em>websites,</em> conteúdo dos serviços ligados à ambientes que não os nossos.</p>
            <br/><p><strong>Serviços de parceiros.</strong> <strong>Nós</strong> possuímos parceiros comerciais que, eventualmente, podem oferecer serviços por meio de funcionalidades ou <em>websites</em> que podem ser acessados a partir de Nossos Ambientes. Os Dados fornecidos por <strong>Você</strong> a estes parceiros serão de responsabilidade destes, estando assim sujeitos às suas próprias práticas de coleta e uso de dados.</p>
            <br/><p><strong>Tratamento por terceiros sob nossa diretriz.</strong> Caso empresas terceirizadas realizem o Tratamento em nosso nome de quaisquer Dados Pessoais que coletamos, elas respeitarão as condições aqui estipuladas e as normas de segurança da informação, obrigatoriamente.</p>
            <br/><p><strong>Comunicação por <em>e-mail</em>.</strong> Para otimizar e melhorar nossa comunicação, quando enviamos um <em>e-mail</em> para <strong>Você</strong> podemos receber uma notificação quando eles são abertos, desde que esta possibilidade esteja disponível. <strong>Nós</strong> não utilizamos serviços de terceiros para enviar <em>e-mails</em> em nosso nome. É importante <strong>Você</strong> ficar atento, pois os e-mails são enviados somente pelo domínio: @classapp.com.br; @clsp.co; ou @classapp.co.</p>
            <br/><p>Caso <strong>Você</strong> receba um <em>e-mail</em> que acredita não ter sido enviado por <strong>Nós</strong>, <strong>Você</strong> deverá se abster de adotar qualquer ação e deverá entrar em contato conosco imediatamente por meio de nossos Canais de Atendimento, a fim de confirmar sua veracidade.</p>
            <br/><p><strong>COMO ARMAZENAMOS SEUS DADOS PESSOAIS E O REGISTRO DE ATIVIDADES</strong></p>
            <br/><p>Os Dados Pessoais coletados e os registros de atividades são armazenados em ambiente seguro e controlado por um prazo mínimo que segue a tabela abaixo:</p>
            <br/><br/><p><strong>PRAZO DE ARMAZENAMENTO</strong></p>
            <br/><p>Mantemos seus dados apenas pelo tempo necessário para cumprir a finalidade pela qual foram coletados, ou quando houver justificativa legal, como em auditorias ou obrigações legais. Durante esse período, seus dados ficam protegidos até serem excluídos ou transformados em informações anônimas (que não permitem identificar <strong>Você</strong>).</p>
            <br/><br/><p style={{ border: '1px solid black', padding: '1rem 1rem 1rem'}}>Os Dados coletados serão armazenados em nossos servidores localizados no Brasil, bem como em ambiente de uso de recursos ou servidores na nuvem (<em>cloud computing</em>), <strong>o que poderá exigir uma transferência e/ou tratamento destes Dados fora do Brasil.</strong></p>
            <br/><br/><p><strong>QUAIS SÃO OS SEUS DIREITOS E COMO EXERCÊ-LOS</strong></p>
            <br/><p>Qualquer que seja a sua relação conosco, <strong>Você</strong> pode exercer todos os direitos relacionados aos seus dados pessoais garantidos pela LGPD</p>
            <br/><div style={{marginLeft: `30px`}}>
              <p>Confirmar se tratamos os seus dados.</p>
              <p>Solicitar acesso aos seus dados tratados por nós.</p>
              <p>Corrigir dados incompletos, inexatos ou desatualizados.</p>
              <p>Ter os dados desnecessários, excessivos ou tratados em desconformidade com a LGPD anonimizados, bloqueados ou eliminados.</p>
              <p>Solicitar a portabilidade dos seus dados.</p>
              <p>Solicitar a eliminação dos dados pessoais que foram tratados com base no seu consentimento, quando aplicável.</p>
              <p>Saber com quais entidades públicas e privadas compartilhamos os seus dados.</p>
              <p>Obter informações sobre a possibilidade de não fornecer consentimento e as consequências da negativa.</p>
              <p>Revogar o seu consentimento, quando aplicável.</p>
              <p>Solicitar a revisão de decisões tomadas unicamente com base em tratamento automatizado. </p>
            </div>
            <br/><p><strong>INFORMAÇÕES SOBRE ESSA POLÍTICA</strong></p>
            <br/><p><strong>Alteração do teor e atualização.</strong> <strong>Você</strong> reconhece o nosso direito de alterar o teor desta Política a qualquer momento, conforme a finalidade ou necessidade, tal qual para adequação e conformidade legal de disposição de lei ou norma que tenha força jurídica equivalente, cabendo a <strong>Você</strong> verificá-la sempre que efetuar o acesso aos Nossos Ambientes ou utilizar nossos serviços e funcionalidades.</p>
            <br/><br/><p>Ocorrendo atualizações neste documento e que demandem nova coleta de consentimento, <strong>Você</strong> poderá ser notificado por meio dos canais de contatos que <strong>Você</strong> informar ou através de seu acesso em Nossos Ambientes.</p>
            <br/><p>Caso <strong>Você</strong> não concorde com referidas alterações, <strong>Você</strong> poderá nos solicitar o cancelamento de sua Conta de Acesso, ficando ciente das implicações de sua ação, em especial, da impossibilidade de utilização de nossos serviços e funcionalidades disponíveis em Nossos Ambientes.</p>
            <br/><p><strong>Inaplicabilidade.</strong> Caso algum ponto desta Política seja considerado inaplicável por Autoridade de Dados ou judicial, as demais condições permanecerão em pleno vigor e efeito.</p>
            <br/><p><strong>Comunicação eletrônica.</strong> <strong>Você</strong> reconhece que toda comunicação realizada por <em>e-mail</em> (aos endereços informados no seu cadastro), SMS, aplicativos de comunicação instantânea ou qualquer outra forma digital, também são válidas, eficazes e suficiente para a divulgação de qualquer assunto que se refira às funcionalidades que oferecemos em Nossos Ambientes, aos seus Dados, bem como qualquer outro assunto abordado, sendo exceção apenas o que essa Política prever como tal.</p>
            <br/><p><strong>Canais de Atendimento.</strong> Em caso de qualquer dúvida com relação às disposições constantes desta Política de Privacidade e Tratamento de Dados, <strong>Você</strong> poderá entrar em contato por meio dos Canais de Atendimento apontados a seguir:</p>
            <br/><p>Canais para dúvidas:</p>
            <br/><p><strong>Chat <em>Online</em>:</strong> ferramenta disponível no Portal ClassApp para envio de mensagens instantâneas, cujo horário de funcionamento é de segunda-feira a sexta-feira, das 09 horas às 17 horas, exceto em feriados adotados por <strong>Nós</strong>;</p>
            <br/><p><strong>Envie uma Solicitação:</strong> através da aba “Ajuda” disponível em Nossos Ambientes, será possível nos enviar uma mensagem contendo sua solicitação; e</p>
            <br/><p><strong>Envio de correspondência física:</strong> RUA GUARARAPES, Nº 00136, , KUHL -VL. - LIMEIRA/SP - CEP: 13480-000.</p>
            <br/><p>Para exercer seus direitos em relação aos dados pessoais tratados pelo Grupo Arco, envie um e-mail para privacidade@arcoeducacao.com.br.</p>
            <br/><p><strong>Lei aplicável e foro.</strong> Essa Política será interpretada segundo a legislação brasileira, no idioma português, sendo eleito o foro do seu domicílio para dirimir qualquer controvérsia que envolva este documento, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.</p>
            <br/><p><strong>Você</strong>, caso não possua domicílio no Brasil, e em razão dos serviços oferecidos por <strong>Nós</strong> ser apenas em território nacional, se submete à legislação brasileira, concordando, portanto, que em havendo litígio a ser solucionado, a ação deverá ser proposta no Foro da Comarca de Limeira, Estado de São Paulo.</p>

          </section>
        }

        {
          (entityTypes.includes('staff') || entityTypes.includes('admin')) &&
          <section>
            <div id="policy-staff-admin">
              <h2 id="h.v3m3td69ppdk">
                POLÍTICA DE PRIVACIDADE E TRATAMENTO DE DADOS DA CLASSAPP PARA
                ADMINISTRADOR DE SISTEMA E FUNCIONÁRIO DA INSTITUIÇÃO DE ENSINO
                (“POLÍTICA”)
              </h2>
              <br/><p>Atualizada em 06 de dezembro de 2024.</p>
              <br/><p><b>INTRODUÇÃO</b></p>
              <br/><p>A presente Política tem por finalidade demonstrar o compromisso da <b>CLASSAPP SISTEMAS LTDA.</b>,
                (“<b>Nós</b>”), com a privacidade e a proteção dos seus Dados.
                Estamos localizados na RUA GUARARAPES, Nº 00136, , KUHL -VL. -
                LIMEIRA/SP - CEP: 13480-000 e somos registrados no CNPJ sob o n.º
                21.560.411/0001-01. Esta Política visa garantir, de forma clara e
                transparente, a conformidade com as leis em vigor, em especial a Lei n.º
                13.709/2018 (“Lei Geral de Proteção de Dados Pessoais”).
              </p>
              <br/><p>
                Esta Política descreve as principais diretrizes sobre o tratamento dos
                seus Dados, em especial Dados Pessoais, quando <b>Você</b> faz uso dos
                nossos serviços e funcionalidades, seja por meio da área logada
                disponível em nosso site institucional &lt;www.classapp.com.br&gt;
                (“Portal ClassApp”) ou por meio do acesso ao nosso aplicativo
                (“Aplicativo ClassApp”), em conjunto denominados de “Nossos Ambientes”.
              </p>
              <br/><p>
                <b>Nós</b> divulgamos esta Política para lhe dar transparência
                sobre as regras de privacidade aplicáveis, garantindo que o tratamento
                dos Dados Pessoais servirá apenas para possibilitar a prestação de
                nossos serviços, observando-se todos os princípios previstos na
                legislação de proteção de dados aplicável. Caso tenha qualquer dúvida,
                estamos à disposição para ouvi-lo. Sua opinião é muito importante para <b>Nós</b>.
              </p>
              <br/><p>
                Para acessar e utilizar das funcionalidades ofertadas em Nossos
                Ambientes,  <u><b>Você deve </b>estar plenamente ciente dos termos aqui estipulados, incluindo a
                  coleta dos Dados aqui mencionados, bem como seu tratamento para os
                  fins abaixo especificados</u>.
              </p>
              <br/><p>
                Estamos sempre buscando lhes oferecer serviços e funcionalidades com a
                maior eficiência possível, nos atualizando constantemente para isto. Por
                esse motivo, esta Política poderá sofrer ajustes a qualquer tempo,
                cabendo a <b>Você</b> verificá-la sempre que possível por meio do acesso
                aos Nossos Ambientes.
              </p>
              <br/><p></p>
              <br/><p><b>DEFINIÇÕES</b> </p>
              <br/><p>
                Sem prejuízo de outras definições constantes nesta Política, as palavras
                e expressões abaixo indicadas, sempre que utilizadas pela primeira letra
                maiúscula, terão as seguintes definições:
              </p>
              <br/><p>
                <b>Anonimização:</b> Utilização de meios técnicos razoáveis e disponíveis
                no momento do Tratamento, por meio dos quais um Dado perde a possibilidade
                de associação, direta ou indireta, a um indivíduo.
              </p>
              <br/><p>
                <b>Aplicativo ClassApp:</b> Aplicativo disponibilizado às Instituições de
                Ensino com a finalidade de proporcionar uma alternativa conveniente para
                que estas interajam com nossos conteúdos e utilizem as funcionalidades e
                serviços oferecidos, podendo ser baixado por meio do <i>App Store</i> e <i>Google Play</i>.
              </p>
              <br/><p>
                <b>Cloud Computing</b><b>:</b> Ou computação em nuvem, é a tecnologia de
                virtualização de serviços construída a partir da interligação de mais de
                um servidor por meio de uma rede de informação comum (p.ex. a <i>Internet</i>), com objetivo de reduzir custos e aumentar a
                disponibilidade dos serviços sustentados.
              </p>
              <br/><p>
                <b>Conta de Acesso:</b> Credencial de um Usuário que permite acesso à área
                restrita e às funcionalidades exclusivas existentes no Portal ClassApp e
                no Aplicativo ClassApp, definida pela combinação de <i>e-mail</i> ou
                número de celular e senha.
              </p>
              <br/><p>
                <b>Cookies</b><b>:</b> Pequenos arquivos digitais em formato de texto que
                são armazenados no seu dispositivo (computador, <i>smartphone</i>, <i>tablet</i> etc.) pelo navegador de <i>internet</i> e que guardam
                informações relacionadas às suas preferências, como idioma preferido,
                localização, recorrência das suas sessões, e outras variáveis que os
                desenvolvedores consideram relevantes para tornar a sua experiência muito
                mais eficiente.
              </p>
              <br/><p>
                <b>Dados:</b> Quaisquer informações inseridas, tratadas ou transmitidas
                através dos Nossos Ambientes.
              </p>
              <br/><p>
                <b>Dados Pessoais:</b> Dados relacionados à pessoa natural identificada ou
                identificável.
              </p>
              <br/><p>
                <b>Decisões unicamente automatizadas:</b> Trata-se de decisões que afetam
                um usuário que foram programadas para funcionar automaticamente, sem a
                necessidade de uma operação humana, com base em Tratamento automatizado de
                Dados Pessoais.
              </p>
              <br/><p>
                <b>Encarregado (</b><b>Data Protection Officer -DPO</b><b>):</b> Pessoa
                indicada por <b>Nós</b> para atuar como canal de comunicação entre <b>Nós</b>, os titulares dos Dados Pessoais e a Autoridade Nacional de
                Proteção de Dados (ANPD).
              </p>
              <br/><p>
                <b>ID </b> <b>de Sessão:</b> Identificação da sessão de Usuários quando é
                efetuado o acesso em Nossos Ambientes.
              </p>
              <br/><p>
                <b>Instituições de Ensino:</b> Instituições de ensino clientes, que
                contratam nossos serviços para utilização por seus funcionários,
                representantes, terceiros, alunos e responsáveis por alunos.
              </p>
              <br/><p>
                <b>IP</b><b>:</b> Abreviatura de <i>Internet Protocol</i>. É um conjunto
                alfanumérico que identifica os dispositivos dos usuários na <i>Internet</i>.
              </p>
              <br/><p><b>Link</b><b>:</b> Terminologia para endereço de <i>internet.</i></p>
              <br/><p>
                <b>Login</b><b>:</b> Processo para acessar um sistema informático restrito
                feito através da autenticação ou identificação do Usuário, usando
                credenciais previamente cadastradas no sistema.
              </p>
              <br/><p>
                <b>Portal ClassApp:</b> Designa a área logada constante em nosso site
                institucional, disponível através do endereço eletrônico
                &lt;www.classapp.com.br&gt;.
              </p>
              <br/><p>
                <b>Termo:</b> Contrato eletrônico, incluindo-se termos e condições gerais,
                que regulam a prestação dos serviços pela <b>ClassApp</b> às Instituições
                de Ensino.
              </p>
              <br/><p>
                <b>Tratamento:</b> Toda operação realizada com Dados Pessoais, como as que
                se referem a coleta, produção, recepção, classificação, utilização,
                acesso, reprodução, transmissão, distribuição, arquivamento,
                armazenamento, eliminação, avaliação ou controle da informação,
                modificação, comunicação, transferência, difusão ou extração.
              </p>
              <br/><p>
                <b>Usuário(s) ou Você:</b> Pessoas físicas, autorizadas pelas Instituições
                de Ensino a utilizar Nossos Ambientes, através dos perfis de acessos como
                “funcionários” ou “administradores/gerenciadores de sistema”.
              </p>
              <br/><p><b>SOBRE DADOS QUE COLETAMOS</b></p>
              <br/><p><b>Como coletamos Dados?</b></p>
              <br/><p>
                <b>Nós</b> coletamos Dados Pessoais mínimos, isto é, apenas aqueles
                necessários para criação da Conta de Acesso do Usuário. Também,
                poderemos coletar Dados Pessoais quando <b>Você</b> interage em Nossos
                Ambientes e serviços, o que inclui:
              </p>
              <br/><ol start="1">
                <li><b>O que coletamos?</b></li>
              </ol>
              <br/><p><b>Dados cadastrais</b></p>
              <ul>
                <li>Nome completo</li>
                <li>CPF</li>
                <li>E-mail</li>
                <li>Telefone celular</li>
                <li>Função exercida na Instituição de Ensino</li>
                <li>Foto de perfil (opcional)</li>
              </ul>
              <br/><p><b>Dados de mídias</b></p>
              <ul>
                <li>Áudios, fotos e vídeos</li>
              </ul>
              <br/><p><b>Dados de identificação digital</b></p>
              <ul>
                <li><i>ID</i> da Sessão</li>
                <li>Endereço <i>IP</i> e Porta Lógica de Origem</li>
                <li>
                  Informações de dispositivo (tipo do dispositivo, conexões de rede, nome
                  do dispositivo, versão do sistema operacional e conexão de <i>internet</i> utilizada)
                </li>
                <li>Geolocalização</li>
                <li>Registros de data e horário de cada ação que <b>Você</b> realizar</li>
                <li>Quais telas <b>Você</b> acessou</li>
                <li><i>Cookies</i></li>
              </ul>
              <br/><ol start="2">
                <li><b>Para que coletamos?</b></li>
              </ol>
              <br/><p><b>Dados Cadastrais</b></p>
              <ul>
                <li>Identificar e autenticar <b>Você</b>;</li>
              </ul>
              <ul>
                <li>
                  Cumprir as obrigações contratuais previstas no Termo e demais obrigações
                  decorrentes do uso dos nossos serviços e funcionalidades, inclusive para
                  atendimento de disposições legais e regulatórias;
                </li>
                <li>
                  Possibilitar a criação de uma Conta de Acesso para utilização de nossos
                  serviços e funcionalidades, inclusive para realização de simulações em
                  Nossos Ambientes;
                </li>
                <li>Atender adequadamente às suas solicitações e dúvidas;</li>
                <li>
                  Aperfeiçoar o uso e a experiência interativa durante sua navegação em
                  Nossos Ambientes;
                </li>
                <li>
                  Promover nossos serviços, além de lhe informar sobre novidades,
                  funcionalidades, conteúdos, notícias e demais informações relevantes
                  para <b>Você</b>;
                </li>
                <li>
                  Proteger <b>Você</b> no que diz respeito à prevenção de fraudes e riscos
                  associados, além do cumprimento de obrigações legais e regulatórias;
                </li>
              </ul>
              <br/><p><b>Dados de mídias</b></p>
              <ul>
                <li>
                  Possibilita a utilização por <b>Você </b> da funcionalidade relativa ao
                  compartilhamento de mídias em Nossos Ambientes;
                </li>
              </ul>
              <br/><p><b>Dados de identificação digital</b></p>
              <ul>
                <li>Identificar e autentificar <b>Você</b>;</li>
                <li>
                  Efetuar estatísticas, estudos, pesquisas e levantamentos relacionados ao
                  comportamento do Usuário em Nossos Ambientes, realizando tais operações
                  de forma anonimizada;
                </li>
                <li>Corrigir problemas de tecnologia;</li>
                <li>
                  Proteger <b>Você</b> no que diz respeito à prevenção de fraudes e riscos
                  associados, além do cumprimento de obrigações legais e regulatórias; e
                </li>
                <li>
                  Cumprir com obrigações legais de manutenção de registros estabelecidas
                  pelo Marco Civil da Internet - Lei 12.965/2014.
                </li>
              </ul>
              <br/><p>
                Para finalidade de prevenção a fraudes e garantia da autenticidade dos
                Dados fornecidos, <b>Nós</b> poderemos solicitar outros Dados Pessoais
                adicionais, não previstos na tabela acima, para confirmação das
                informações fornecidas pelos Usuários. Neste caso, <b>Nós</b> poderemos
                entrar em contato diretamente com <b>Você</b> para efetuar a requisição.
              </p>
              <br/><p>
                <b>Dados necessários.</b> A prestação dos nossos serviços e
                funcionalidades disponíveis em Nossos Ambientes dependem diretamente de
                determinados Dados informados na tabela acima, em especial, dos Dados
                cadastrais para criação de sua Conta de Acesso. Caso <b>Você</b> opte
                por não fornecer alguns desses Dados, poderemos ficar impossibilitados
                de executar parte de nossos serviços e funcionalidades em Nossos
                Ambientes.
              </p>
              <br/><p>
                <b>Atualização e veracidade dos Dados.</b> <b>Você</b> é o único
                responsável pela precisão, veracidade ou falta dela em relação aos Dados
                que <b>Você</b> fornece ou pela sua desatualização. Fique atento pois é
                de sua responsabilidade garantir a exatidão ou mantê-los atualizados.
              </p>
              <br/><p>
                Da mesma forma, <b>Nós</b> não somos obrigados a tratar quaisquer dos seus
                Dados se houver razões para crer que tal Tratamento possa nos imputar
                infração da legislação aplicável, ou se <b>Você</b> estiver utilizando
                Nossos Ambientes para quaisquer fins ilegais, ilícitos ou contrários à
                moralidade.
              </p>
              <br/><p>
                <b>Base de Dados.</b> A base de Dados, de propriedade da Instituição de
                Ensino, formada por meio da coleta de Dados, estará sob nossa
                responsabilidade, sendo que seu uso, acesso e compartilhamento, quando
                necessários, serão feitos dentro dos limites e propósitos dos negócios
                descritos nesta Política.
              </p>
              <br/><p>
                <b>Tecnologias empregadas.</b> <b>Nós</b> utilizamos a(s) seguinte(s)
                tecnologia(s):
              </p>
              <br/><ol start="1">
                <li>
                  <i>Cookies</i>, utilizados para melhorar a funcionalidade do Portal
                  ClassApp. A qualquer tempo, <b>Você</b> pode bloquear o uso de <i>Cookies</i> através das configurações o seu navegador de <i>Internet</i>, hipótese em que algumas funcionalidades do Portal
                  ClassApp poderão ser limitadas;
                </li>
                <li>
                  <i>Tags </i>de <i>Pixel</i>, possibilitam a coleta de informações
                  (arquivos gravados em seu computador) referentes ao seu histórico de
                  navegação, facilitam seu <i>login</i> (mediante armazenamento de suas
                  credenciais) e promovem a interação entre <b>Você</b> e os serviços
                  oferecidos por <b>Nós</b>. As <i>Tags</i> de <i>Pixel</i> também poderão
                  ser desabilitadas mediante configurações das opções existentes em seu
                  navegador de <i>Internet</i>;
                </li>
                <li>
                  <i>Log files</i>, registram atividades referentes ao uso de nosso Portal
                  ClassApp, tais como acesso a outros <i>websites</i>, o tipo e a
                  qualidade de conexão de <i>internet</i>, <i>hardwares</i> ou <i>softwares</i> utilizados e as funcionalidades de determinado serviço
                  que tenha sido utilizado por <b>Você</b>. Os <i>log files</i> poderão
                  ser utilizados para monitorar o tráfego no Portal ClassApp e solucionar
                  problemas técnicos;
                </li>
                <li>
                  <i>Web beacons</i>, usados para rastrear anonimamente os seus padrões de
                  uso <i>online</i>, a exemplo de <i>e-mails</i> abertos e <i>links</i> clicados por <b>Você</b>. As informações coletadas poderão
                  identificar <i>websites</i>, serviços, produtos e funcionalidades
                  populares, as quais serão utilizadas para personalizar nossos serviços
                  para <b>Você</b>;
                </li>
                <li>
                  <i>Google Analytics</i>, para registro de dados de navegação não
                  identificáveis para análise;
                </li>
                <li>
                  <i>Adwords</i> e <i>Facebook</i>, para registro de eventos durante a
                  navegação;
                </li>
                <li>
                  <i>Smartlook</i>, usado para entrega de relatórios de monitoramento
                  completos sobre o comportamento do usuário em determinada interface; e
                </li>
                <li>
                  <i>Hotjar</i>, utilizado para criação de “mapa de calor”, o qual indica
                  onde as pessoas mais clicam e até onde rolam a página.
                </li>
              </ol>
              <br/><p>
                <b>Nós</b> também poderemos utilizar ferramentas fornecidas por empresas
                terceiras, as quais registrarão as atividades de interação do Usuário com
                Nossos Ambientes. O Tratamento destes Dados será realizado de forma
                anônima, com a finalidade de melhorar a experiência e conteúdo de Nossos
                Ambientes, observando-se o disposto na cláusula abaixo.
              </p>
              <br/><p>
                Todas as tecnologias utilizadas respeitarão sempre a legislação vigente e
                os termos desta Política.
              </p>
              <br/><p style={{ border: '1px solid black', padding: '1rem 1rem 1rem'}}>
                <b>Nós</b> não utilizamos nenhum tipo de decisão unicamente automatizada
                que impacte <b>Você</b>.
              </p>
              <br/><p><b>COMO COMPARTILHAMOS DADOS E INFORMAÇÕES</b></p>
              <br/><p>
                <b>Hipóteses de compartilhamento dos Dados.</b> Os Dados coletados e as
                atividades registradas podem ser compartilhados, sempre respeitando o
                envio do mínimo de informações necessárias para atingir as finalidades:
              </p>
              <br/><ol start="1">
                <li>
                  Com autoridades judiciais, administrativas ou governamentais
                  competentes, sempre que houver determinação legal, requerimento,
                  requisição ou ordem judicial;
                </li>
                <li>
                  Com empresas parceiras e prestadores de serviços necessários à execução
                  dos nossos serviços e funcionalidades, sempre exigindo de tais
                  organizações o cumprimento das diretrizes de segurança e proteção de
                  dados, conforme item 5.6 desta Política; e
                </li>
                <li>
                  De forma automática, em caso de movimentações societárias, como fusão,
                  aquisição e incorporação.
                </li>
              </ol>
              <br/><p>
                <b>Anonimização de Dados.</b> Para as finalidades de pesquisas de
                inteligência de mercado, divulgação de dados à imprensa, realização de
                propagandas e outras atividades aplicáveis, os dados fornecidos por <b>Você</b> serão compartilhados de forma anonimizada, isto é, de forma
                que não possibilite a sua identificação.
              </p>
              <br/><p><b>COM QUEM COMPARTILHAMOS OS SEUS DADOS PESSOAIS?</b></p>
              
              <div style={{marginLeft: `30px`}}>
                <br/><p>
                  Podemos compartilhar seus dados pessoais com algumas pessoas e empresas,
                  como:
                </p>
                <br/><p>Empresas do Grupo Arco;</p>
                <p>Escolas parceiras;</p>
                <p>Fornecedores e parceiros;</p>
                <p>Autoridades.</p>
                <br/><p>Os principais objetivos desse compartilhamento são:</p>
                <br/><p>Assessoria jurídica e contábil;</p>
                <p>Serviços de proteção ao crédito e cobrança;</p>
                <p>Análises, marketing e prospecção;</p>
                <p>Venda dos nossos produtos e serviços;</p>
                <p>Segurança e prevenção à fraudes;</p>
                <p>Cumprimento de obrigações legais ou ordens judiciais;</p>
                <p>Alterações societárias, como fusões e aquisições.</p>
              </div>

              <br/><p><b>COMO PROTEGEMOS SEUS DADOS E COMO VOCÊ TAMBÉM PODERÁ PROTEGÊ-LOS</b></p>
              <br/><p><b>Medidas que tomamos.</b> <b>Nós</b> empregamos os melhores esforços
                para manter a privacidade e a segurança das informações por meio da
                adoção de medidas de segurança técnicas, físicas e administrativas:
              </p>
              <br/><ol start="1">
                <li>
                  <b>Medidas técnicas</b>, como por exemplo transmissão de Dados Pessoais
                  por meio de página de <i>internet </i> segura, armazenamento de Dados em
                  meios eletrônicos que mantêm altos padrões de segurança, utilização de
                  sistema cujo acesso é controlado;
                </li>
                <li>
                  <b>Medidas físicas</b>, tais como acesso restrito a pessoas autorizadas
                  mantido em instalações que incluem, uso de ferramentas de segurança de
                  mercado; e
                </li>
                <li>
                  <b>Medidas administrativas</b>, incluindo a adoção de Políticas e Normas
                  de Segurança, treinamentos/conscientização de colaboradores, acordos de
                  confidencialidade.
                </li>
              </ol>
              <br/><p>
                <b>Compartilhamento de senhas.</b> <b>Você</b> também é responsável pelo
                sigilo de seus Dados Pessoais e deve ter sempre ciência de que o
                compartilhamento de senhas e dados de acesso viola esta Política e pode
                comprometer a segurança dos seus Dados e dos Nossos Ambientes.
              </p>
              <br/><p>
                <b>Cuidados que Você dever tomar.</b> É muito importante que <b>Você</b> proteja seus Dados contra acesso não autorizado ao seu
                dispositivo, conta ou senha. Nossos contatos têm a finalidade de trazer
                informações sobre suas dúvidas e solicitações, bem como demais
                informações relevantes para <b>Você</b>.
              </p>
              <br/><p>
                <b>Acesso aos Dados Pessoais, proporcionalidade e relevância.</b> Internamente, os Dados Pessoais coletados são
                acessados somente por
                profissionais devidamente autorizados, respeitando os princípios de
                proporcionalidade, necessidade e relevância para os objetivos do nosso
                negócio, além do compromisso de confidencialidade e preservação da sua
                privacidade disposto na presente Política. Profissionais e/ou pessoas
                autorizadas que se utilizarem indevidamente desses Dados, violando os
                termos desta Política, estarão sujeitos às penalidades previstas em
                nosso processo disciplinar, sem exclusão das demais penalidades legais
                aplicáveis.
              </p>
              <br/><p>
                <b>Links </b><b>externos.</b> Quando <b>Você</b> utilizar Nossos
                Ambientes, <b>Você</b> poderá ser conduzido, via <i>link</i> a outros
                portais ou plataformas, inclusive ambientes pertencentes a terceiros
                prestadores de serviços das Instituições de Ensino, como resultado da
                integração de Nossos Ambientes com ambientes de terceiros requisitada
                pela Instituição de Ensino, podendo estes coletar suas informações e ter
                sua própria Política de Tratamento de Dados.
              </p>
              <br/><p>
                Caberá a <b>Você</b> ler a Políticas de Privacidade e Tratamento de Dados
                de tais portais ou plataformas fora de Nossos Ambientes, sendo de sua
                responsabilidade estar ciente do seu conteúdo. <b>Nós</b> não somos
                responsáveis pelas Políticas de Privacidade e Tratamento de Dados de
                terceiros e nem pelo conteúdo de quaisquer <i>websites,</i> conteúdo dos
                serviços ligados à ambientes que não os nossos.
              </p>
              <br/><p>
                Serviços de parceiros. <b>Nós</b> possuímos parceiros comerciais que,
                eventualmente, podem oferecer serviços por meio de funcionalidades ou <i>websites</i> que podem ser acessados a partir de Nossos Ambientes. Os
                Dados fornecidos por <b>Você</b> a estes parceiros serão de
                responsabilidade destes, estando assim sujeitos às suas próprias práticas
                de coleta e uso de dados.
              </p>
              <br/><p></p>
              <br/><p>
                <b>Tratamento por terceiros sob nossa diretriz.</b> Caso empresas
                terceirizadas realizem o Tratamento em nosso nome de quaisquer Dados
                Pessoais que coletamos, elas respeitarão as condições aqui estipuladas e
                as normas de segurança da informação, obrigatoriamente.
              </p>
              <br/><p>
                <b>Comunicação por </b><b>e-mail</b><b>.</b> Para otimizar e melhorar
                nossa comunicação, quando enviamos um <i>e-mail</i> para <b>Você</b> podemos receber uma notificação quando eles são abertos,
                desde que esta possibilidade esteja disponível. <b>Nós</b> não
                utilizamos serviços de terceiros para enviar <i>e-mails</i> em nosso
                nome. É importante <b>Você</b> ficar atento, pois os e-mails são
                enviados somente pelos domínios: @classapp.com.br; @clsp.co; ou
                @classapp.co.
              </p>
              <br/><p>
                Caso <b>Você</b> receba um <i>e-mail</i> que acredita não ter sido enviado
                por <b>Nós</b>, <b>Você</b> deverá se abster de adotar qualquer ação e
                deverá entrar em contato conosco imediatamente por meio de nossos Canais
                de Atendimento, a fim de confirmar sua veracidade.
              </p>
              <br/><p>
                <b>COMO ARMAZENAMOS SEUS DADOS PESSOAIS E O REGISTRO DE ATIVIDADES</b>
              </p>
              <br/><p>
                Os Dados Pessoais coletados e os registros de atividades são armazenados
                em ambiente seguro e controlado por um prazo mínimo que segue a tabela
                abaixo:
              </p>
              <br/><p><b>PRAZO DE ARMAZENAMENTO</b></p>
              <br/><p>
                Mantemos seus dados apenas pelo tempo necessário para cumprir a
                finalidade pela qual foram coletados, ou quando houver justificativa
                legal, como em auditorias ou obrigações legais. Durante esse período,
                seus dados ficam protegidos até serem excluídos ou transformados em
                informações anônimas (que não permitem identificar <b>Você</b>).
              </p>
              <br/><p>
                <b>Prazos de armazenamento superiores.</b> Para fins de auditoria,
                segurança, controle de fraudes e preservação de direitos poderemos
                permanecer com o histórico de registro de seus Dados por prazo maior nas
                hipóteses que a lei ou norma regulatória, assim estabelecer ou para
                preservação de direitos.
              </p>
              <br/><p style={{ border: '1px solid black', padding: '1rem 1rem 1rem'}}>
                Os Dados coletados serão armazenados em nossos servidores localizados no
                Brasil, bem como em ambiente de uso de recursos ou servidores na nuvem
                (<i>cloud computing</i>), <b>o que poderá exigir uma transferência e/ou processamento destes Dados fora do Brasil.</b>
              </p>
              <br/><p><b>QUAIS SÃO OS SEUS DIREITOS E COMO EXERCÊ-LOS</b></p>
              <p>
                Qualquer que seja a sua relação conosco, <b>Você</b> pode exercer todos os
                direitos relacionados aos seus dados pessoais garantidos pela LGPD:
              </p>
              <div style={{marginLeft: `30px`}}>

                <br/><p>Confirmar se tratamos os seus dados.</p>
                <p>Solicitar acesso aos seus dados tratados por nós.</p>
                <p>Corrigir dados incompletos, inexatos ou desatualizados.</p>
                <p>
                  Ter os dados desnecessários, excessivos ou tratados em desconformidade
                  com a LGPD anonimizados, bloqueados ou eliminados.
                </p>
                <p>Solicitar a portabilidade dos seus dados.</p>
                <p>
                  Solicitar a eliminação dos dados pessoais que foram tratados com base no
                  seu consentimento, quando aplicável.
                </p>
                <p>
                  Saber com quais entidades públicas e privadas compartilhamos os seus
                  dados.
                </p>
                <p>
                  Obter informações sobre a possibilidade de não fornecer consentimento e
                  as consequências da negativa.
                </p>
                <p>Revogar o seu consentimento, quando aplicável.</p>
                <p>
                  Solicitar a revisão de decisões tomadas unicamente com base em
                  tratamento automatizado.
                </p>
              </div>
              <br/><p><b>INFORMAÇÕES SOBRE ESSA POLÍTICA</b></p>
              <br/><p>
                <b>Alteração do teor e atualização.</b> <b>Você</b> está ciente do nosso
                direito de alterar o teor desta Política a qualquer momento, conforme a
                finalidade ou necessidade, tal qual para adequação e conformidade legal
                de disposição de lei ou norma que tenha força jurídica equivalente,
                cabendo a <b>Você</b> verificá-la sempre que efetuar o acesso aos Nossos
                Ambientes ou utilizar nossos serviços e funcionalidades.
              </p>
              <br/><p>
                Ocorrendo atualizações neste documento e que demandem nova coleta de
                consentimento, <b>Você</b> poderá ser notificado por meio dos canais de
                contatos que <b>Você</b> informar ou através de seu acesso em Nossos
                Ambientes.
              </p>
              <br/><p>
                <b>Inaplicabilidade.</b> Caso algum ponto desta Política seja
                considerado inaplicável por Autoridade de Dados ou judicial, as demais
                condições permanecerão em pleno vigor e efeito.
              </p>
              <br/><p>
                <b>Comunicação eletrônica.</b> <b>Você</b> reconhece que toda
                comunicação realizada por <i>e-mail</i> (aos endereços informados no seu
                cadastro), SMS, aplicativos de comunicação instantânea ou qualquer outra
                forma digital, também são válidas, eficazes e suficiente para a
                divulgação de qualquer assunto que se refira às funcionalidades que
                oferecemos em Nossos Ambientes, aos seus Dados, bem como qualquer outro
                assunto abordado, sendo exceção apenas o que essa Política prever como
                tal.
              </p>
              <br/><p>
                <b>Canais de Atendimento.</b> Em caso de qualquer dúvida com relação às
                disposições constantes desta Política de Privacidade e Tratamento de
                Dados, <b>Você</b> poderá entrar em contato por meio dos Canais de
                Atendimento apontados a seguir:
              </p>
              <br/><p>Canais para dúvidas:</p>
              <br/><p>
                <b>Chat </b><b>Online</b> ferramenta disponível no Portal ClassApp para
                envio de mensagens instantâneas, cujo horário de funcionamento é de
                segunda - feira a sexta - feira, das 09 horas às 17 horas, exceto em
                feriados adotados por <b>Nós</b>;
              </p>
              <br/><p>
                <b>Envie uma Solicitação</b> através da aba “Ajuda” disponível em Nossos
                Ambientes, será possível nos enviar uma mensagem contendo sua solicitação;
                e
              </p>
              <br/><p>
                <b>Envio de correspondência física</b> RUA GUARARAPES, Nº 00136, , KUHL
                -VL. - LIMEIRA/SP - CEP: 13480-000.
              </p>
              <br/><p>
                Para exercer seus direitos em relação aos dados pessoais tratados pelo
                Grupo Arco, envie um e-mail para privacidade@arcoeducacao.com.br.
              </p>
              <br/><p>
                <b>Lei aplicável e foro.</b> Essa Política será interpretada segundo a
                legislação brasileira, no idioma português, sendo eleito o foro da
                Comarca de Limeira, Estado de São Paulo, para dirimir qualquer
                controvérsia que envolva este documento, salvo ressalva específica de
                competência pessoal, territorial ou funcional pela legislação aplicável.
              </p>
            </div>
          </section >

        } */}
      </div>
    );
  }
}
