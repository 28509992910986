/* eslint-disable sonarjs/no-duplicate-string */
import getEntityMenuItems from '../getEntityMenuItems';

jest.mock('../../../lib/analytics');

describe('Given the getEntityMenuItems function', () => {
  /**
   * @param {import '../getEntityMenuItems'.IGetEntityMenuItems} params
   */
  const mockParams = {
    type: 'ADMIN',
    invisible: false,
    id: 1,
    viewingUser: {
      entities: {
        nodes: [
          {
            id: 1,
            type: 'ADMIN',
            organization: {
              id: 1,
            },
            disabled: false,
          },
        ],
      },
    },
    organization: {
      id: 1,
      unleashStatus: {
        web_menu_conversations: true,
      },
      features: {
        moments: true,
        commitments: true,
      },
      productFeatures: {
        nodes: [
          {
            identifier: 'attendanceChannel',
          },
        ],
      },
    },
    isChanel: false,
    unreadCommunicationCount: 1,
    unreadMomentCount: 2,
    unreadConversationCount: 3,
    currentOrganization: {
      id: 1,
      productFeatures: {
        nodes: [
          {
            identifier: 'attendanceChannel',
          },
        ],
      },
    },
    unleash: {
      unleash: {
        toggles: [
          {
            name: 'web_menu_conversations',
          },
        ],
      },
    },
    currentEntityHasUnreadDirectMessages: 0,
    currentEntityType: 'ADMIN',
    accessAccountAnalytics: jest.fn(),
  };

  it('should return the correct menu items when organization is not network and entity is admin', () => {
    const { items, mobileItems } = getEntityMenuItems(mockParams);

    const accessAccountAnalytics = () => {};

    expect(JSON.stringify(items)).toEqual(
      JSON.stringify([
        {
          icon: 'tachometer alt',
          name: 'Dashboard',
          to: '/entities/1/dashboard',
        },
        {
          icon: 'browser',
          name: 'Accounts',
          to: '/entities/1/accounts',
          id: 'EntitysAccount',
          analyticsCallback: accessAccountAnalytics,
        },
        {
          icon: 'pencil',
          name: 'Edit',
          to: '/entities/1/edit',
          id: 'EditEntity',
          analyticsCallback: () => {},
        },
        {
          icon: 'envelope',
          name: 'Communications',
          quantity: 1,
          to: '/entities/1/messages',
        },
        {
          icon: 'message',
          name: 'Chats',
          quantity: 3,
          to: '/entities/1/chats',
        },
        {
          icon: 'heart',
          name: 'Moments',
          quantity: 2,
          to: '/entities/1/moments',
        },
        {
          icon: 'image',
          name: 'Media',
          to: '/entities/1/medias',
        },
        {
          icon: 'calendar',
          name: 'Calendar',
          to: '/entities/1/commitments',
        },
      ]),
    );

    expect(JSON.stringify(mobileItems)).toEqual(
      JSON.stringify([
        {
          icon: 'browser',
          name: 'Accounts',
          to: '/entities/1/accounts',
          analyticsCallback: accessAccountAnalytics,
        },
        {
          icon: 'pencil',
          name: 'Edit',
          to: '/entities/1/edit',
          id: 'EditEntity',
          analyticsCallback: () => {},
        },
        {
          icon: 'envelope',
          name: 'Communications',
          quantity: 1,
          to: '/entities/1/messages',
          default: '/entities/1',
        },
        {
          icon: 'message',
          name: 'Chats',
          quantity: 3,
          to: '/entities/1/chats',
        },
        {
          icon: 'heart',
          name: 'Moments',
          quantity: 2,
          to: '/entities/1/moments',
        },
        {
          icon: 'image',
          name: 'Media',
          to: '/entities/1/medias',
        },
        {
          icon: 'calendar',
          name: 'Calendar',
          to: '/entities/1/commitments',
        },
      ]),
    );
  });

  it('should return the correct menu items when organization is network and entity is admin', () => {
    const accessAccountAnalytics = () => {};
    const { items, mobileItems } = getEntityMenuItems({
      ...mockParams,
      currentOrganization: {
        ...mockParams.currentOrganization,
        network: {
          id: 1,
        },
        unleashStatus: {
          network_communication: true,
        },
      },
      organization: {
        ...mockParams.organization,
        unleashStatus: {
          ...mockParams.organization.unleashStatus,
          network_communication: true,
        },
      },
    });

    expect(JSON.stringify(items)).toEqual(
      JSON.stringify([
        {
          icon: 'tachometer alt',
          name: 'Dashboard',
          to: '/entities/1/dashboard',
        },
        {
          icon: 'browser',
          name: 'Accounts',
          to: '/entities/1/accounts',
          id: 'EntitysAccount',
          analyticsCallback: accessAccountAnalytics,
        },
        {
          icon: 'pencil',
          name: 'Edit',
          to: '/entities/1/edit',
          id: 'EditEntity',
          analyticsCallback: () => {},
        },
        {
          icon: 'envelope',
          name: 'Communications',
          quantity: 1,
          to: '/entities/1/messages',
        },
        {
          icon: 'image',
          name: 'Media',
          to: '/entities/1/medias',
        },
      ]),
    );

    expect(JSON.stringify(mobileItems)).toEqual(
      JSON.stringify([
        {
          icon: 'browser',
          name: 'Accounts',
          to: '/entities/1/accounts',
          analyticsCallback: accessAccountAnalytics,
        },
        {
          icon: 'pencil',
          name: 'Edit',
          to: '/entities/1/edit',
          id: 'EditEntity',
          analyticsCallback: () => {},
        },
        {
          icon: 'envelope',
          name: 'Communications',
          quantity: 1,
          to: '/entities/1/messages',
          default: '/entities/1',
        },
        {
          icon: 'image',
          name: 'Media',
          to: '/entities/1/medias',
        },
      ]),
    );
  });

  it('should return the correct menu items when organization is not network and entity is staff', () => {
    const { items, mobileItems } = getEntityMenuItems({
      ...mockParams,
      type: 'staff',
      viewingUser: {
        entities: {
          nodes: [
            {
              id: 1,
              type: 'STAFF',
              organization: {
                id: 1,
              },
              disabled: false,
            },
          ],
        },
      },
    });

    expect(JSON.stringify(items)).toEqual(
      JSON.stringify([
        {
          icon: 'tachometer alt',
          name: 'Dashboard',
          to: '/entities/1/dashboard',
        },
        {
          icon: 'pencil',
          name: 'Edit',
          to: '/entities/1/edit/picture',
        },
        {
          icon: 'envelope',
          name: 'Communications',
          quantity: 1,
          to: '/entities/1/messages',
        },
        {
          icon: 'heart',
          name: 'Moments',
          quantity: 2,
          to: '/entities/1/moments',
        },
        {
          icon: 'image',
          name: 'Media',
          to: '/entities/1/medias',
        },
        {
          icon: 'calendar',
          name: 'Calendar',
          to: '/entities/1/commitments',
        },
      ]),
    );

    expect(JSON.stringify(mobileItems)).toEqual(
      JSON.stringify([
        {
          icon: 'pencil',
          name: 'Edit',
          to: '/entities/1/edit/picture',
        },
        {
          icon: 'envelope',
          name: 'Communications',
          quantity: 1,
          to: '/entities/1/messages',
          default: '/entities/1',
        },
        {
          icon: 'heart',
          name: 'Moments',
          quantity: 2,
          to: '/entities/1/moments',
        },
        {
          icon: 'image',
          name: 'Media',
          to: '/entities/1/medias',
        },
        {
          icon: 'calendar',
          name: 'Calendar',
          to: '/entities/1/commitments',
        },
      ]),
    );
  });

  it('should return the correct menu items when organization is network and entity is staff', () => {
    const { items, mobileItems } = getEntityMenuItems({
      ...mockParams,
      type: 'STAFF',
      viewingUser: {
        entities: {
          nodes: [
            {
              id: 1,
              type: 'STAFF',
              organization: {
                id: 1,
              },
              disabled: false,
            },
          ],
        },
      },
      currentOrganization: {
        ...mockParams.currentOrganization,
        network: {
          id: 1,
        },
        unleashStatus: {
          ...mockParams.organization.unleashStatus,
          network_communication: true,
        },
      },
    });

    expect(JSON.stringify(items)).toEqual(
      JSON.stringify([
        {
          icon: 'tachometer alt',
          name: 'Dashboard',
          to: '/entities/1/dashboard',
        },
        {
          icon: 'pencil',
          name: 'Edit',
          to: '/entities/1/edit/picture',
        },
        {
          icon: 'envelope',
          name: 'Communications',
          quantity: 1,
          to: '/entities/1/messages',
        },
        {
          icon: 'image',
          name: 'Media',
          to: '/entities/1/medias',
        },
      ]),
    );

    expect(JSON.stringify(mobileItems)).toEqual(
      JSON.stringify([
        {
          icon: 'pencil',
          name: 'Edit',
          to: '/entities/1/edit/picture',
        },
        {
          icon: 'envelope',
          name: 'Communications',
          quantity: 1,
          to: '/entities/1/messages',
          default: '/entities/1',
        },
        {
          icon: 'image',
          name: 'Media',
          to: '/entities/1/medias',
        },
      ]),
    );
  });
});
