import { checkEntitiesHasMessagesScheduled } from '../index';
import * as repository from '../../../../../Data/Repository/Message/GetEntitiesWhoHasMessagesScheduled';

describe('checkEntitiesHasMessagesScheduled', () => {
  let useCase;

  beforeEach(() => {
    useCase = checkEntitiesHasMessagesScheduled;
    jest.spyOn(repository, 'GetEntitiesWhoHasMessagesScheduled').mockResolvedValue([]);
  });

  it('should throw an error if entityIds parameter is empty', async () => {
    const input = {
      entityIds: [],
      organizationId: '1',
    };

    await expect(useCase(input)).rejects.toThrow(
      'Missing required input parameters',
    );
  });

  it('should throw an error if entityIds parameter is null', async () => {
    const input = {
      organizationId: '1',
    };

    await expect(useCase(input)).rejects.toThrow(
      'Missing required input parameters',
    );
  });

  it('should throw an error if organizationId parameter are missing', async () => {
    const input = {
      entityIds: ['1'],
    };

    await expect(useCase(input)).rejects.toThrow(
      'Missing required input parameters',
    );
  });

  it('should call repository and go happy path', async () => {
    const input = {
      entityIds: ['1'],
      organizationId: ['1'],
    };

    const response = await useCase(input);

    expect(response).toEqual([]);
    expect(repository.GetEntitiesWhoHasMessagesScheduled).toHaveBeenCalledWith(input);
  });
});
