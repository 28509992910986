import gql from 'graphql-tag';
import { Query } from '../../DataSource/ApolloDataSource';

/**
 * @typedef {{
 *  entityId: string | number,
 * }} TVariables
 */

/**
 * @typedef {Promise<{
 *  data: {
 *    node: {
 *     messages: {
 *        totalCount: number
 *     }
 *     moments: {
 *        totalCount: number
 *     }
 *     directUnreadTotalCount: number
 *    },
 *  } | null,
 *  error?: string
 * }>} TResponse
 */

/**
 * @param {TVariables} variables
 * @returns {TResponse}
 */
export async function getChannelUnreadNotificationRepository(variables) {
  const query = gql`
    query WalleGetEntityMenuCounter($entityId: ID!) {
      node(id: $entityId) {
        ... on Entity {
          messages(folder: UNREAD) {
            totalCount
          }
          moments(folder: NEW) {
            totalCount
          }
          directUnreadTotalCount
        }
      }
    }
  `;

  const { data, error } = await Query(query, variables);

  return { data, error };
}
