import { __ } from '../../i18n';
import { hasConversationProductFeature } from '../../helpers/hasConversationProductFeature';
import { isNetwork } from '../Domain/UseCases/Unleash/validations/IsNetwork';

/**
 * @typedef {{
 * type: 'STUDENT' | 'STAFF' | 'ADMIN';
 * invisible?: boolean;
 * id?: number | string;
 * viewingUser: any;
 * organization: any;
 * unreadCommunicationCount: number;
 * unreadMomentCount: number;
 * unreadConversationCount: number;
 * isChannel: boolean;
 * unleash: any;,
 * currentEntityType: 'ADMIN' | 'STAFF' | 'STUDENT',
 * currentOrganization: any,
 * currentEntityHasUnreadDirectMessages: number,
 * currentUserOrganization: any,
 * accessAccountAnalytics: (category: string) => void,
 * }} IGetEntityMenuItems
 */

/**
 * @param {IGetEntityMenuItems} params
 */
export default function getEntityMenuItems({
  type,
  invisible,
  id,
  viewingUser,
  organization,
  unreadCommunicationCount,
  unreadMomentCount,
  unreadConversationCount,
  isChannel,
  unleash,
  currentOrganization,
  currentEntityType,
  currentUserOrganization,
  accessAccountAnalytics,
}) {
  const items = [];
  const mobileItems = [];

  const organizationIsNetwork = isNetwork(currentOrganization);

  const hasMenuConversations =
    organization?.unleashStatus?.web_menu_conversations ||
    (unleash.unleash.toggles.find(
      (toggle) => toggle.name === 'web_menu_conversations',
    ) &&
      hasConversationProductFeature(currentOrganization));

  /** @type {boolean} */
  const userHasAdminEntityOrIsMaster =
    (viewingUser &&
      viewingUser.entities &&
      viewingUser.entities.nodes &&
      viewingUser.entities.nodes.filter(
        (e) =>
          e.type === 'ADMIN' &&
          e.disabled === false &&
          e.organization &&
          organization &&
          e.organization.id === organization.id,
      ).length) ||
    viewingUser.isMaster;

  const userHasAdminOrStaffEntityOrIsMaster =
    (viewingUser.entities &&
      viewingUser.entities.nodes &&
      viewingUser.entities.nodes.filter(
        (e) =>
          e.id === id &&
          (e.type === 'ADMIN' || e.type === 'STAFF') &&
          e.disabled === false &&
          e.organization &&
          organization &&
          e.organization.id === organization.id,
      ).length) ||
    viewingUser.isMaster;

  if (type !== 'STUDENT' && !invisible) {
    items.push({
      icon: 'tachometer alt',
      name: __('Dashboard'),
      to: `/entities/${id}/dashboard`,
    });
  }

  if (
    !userHasAdminEntityOrIsMaster &&
    (!organizationIsNetwork || (organizationIsNetwork && type === 'STAFF'))
  ) {
    items.push({
      icon: 'pencil',
      name: __('Edit'),
      to: `/entities/${id}/edit/picture`,
    });

    mobileItems.push({
      icon: 'pencil',
      name: __('Edit'),
      to: `/entities/${id}/edit/picture`,
    });
  }

  if (userHasAdminEntityOrIsMaster) {
    if (
      !organizationIsNetwork ||
      (organizationIsNetwork &&
        (type === 'ADMIN' ||
          (currentEntityType === 'ADMIN' &&
            // eslint-disable-next-line sonarjs/no-gratuitous-expressions
            userHasAdminEntityOrIsMaster)))
    ) {
      items.push({
        icon: 'browser',
        name: __('Accounts'),
        to: `/entities/${id}/accounts`,
        id: 'EntitysAccount',
        analyticsCallback: () => accessAccountAnalytics('access_account'),
      });

      mobileItems.push({
        icon: 'browser',
        name: __('Accounts'),
        to: `/entities/${id}/accounts`,
        analyticsCallback: accessAccountAnalytics('access_account'),
      });
    }

    if (
      organization &&
      (!organizationIsNetwork ||
        (organizationIsNetwork && userHasAdminOrStaffEntityOrIsMaster))
    ) {
      items.push({
        icon: 'pencil',
        name: __('Edit'),
        to: isChannel
          ? `/organizations/${organization.id}/channels/${id}/edit`
          : `/entities/${id}/edit`,
        id: 'EditEntity',
        analyticsCallback: () => accessAccountAnalytics('edit_account'),
      });

      mobileItems.push({
        icon: 'pencil',
        name: __('Edit'),
        to: `/entities/${id}/edit`,
        id: 'EditEntity',
        analyticsCallback: () => accessAccountAnalytics('edit_account'),
      });
    }
  }

  mobileItems.push({
    icon: 'envelope',
    name: __('Communications'),
    quantity: unreadCommunicationCount,
    to: `/entities/${id}/messages`,
    default: `/entities/${id}`,
  });

  items.push({
    icon: 'envelope',
    name: __('Communications'),
    quantity: unreadCommunicationCount,
    to: `/entities/${id}/messages`,
  });

  if (
    ((type === 'STAFF' && isChannel) || type === 'ADMIN') &&
    hasMenuConversations &&
    hasConversationProductFeature(organization) &&
    !organizationIsNetwork
  ) {
    mobileItems.push({
      icon: 'message',
      name: __('Chats'),
      quantity: unreadConversationCount,
      to: `/entities/${id}/chats`,
    });
    items.push({
      icon: 'message',
      name: __('Chats'),
      quantity: unreadConversationCount,
      to: `/entities/${id}/chats`,
    });
  }

  if (organization && organization.features.moments && !organizationIsNetwork) {
    items.push({
      icon: 'heart',
      name: __('Moments'),
      quantity: unreadMomentCount,
      to: `/entities/${id}/moments`,
    });
    mobileItems.push({
      icon: 'heart',
      name: __('Moments'),
      quantity: unreadMomentCount,
      to: `/entities/${id}/moments`,
    });
  }

  if (
    !organizationIsNetwork ||
    (organizationIsNetwork && userHasAdminOrStaffEntityOrIsMaster)
  ) {
    mobileItems.push({
      icon: 'image',
      name: __('Media'),
      to: `/entities/${id}/medias`,
    });
    items.push({
      icon: 'image',
      name: __('Media'),
      to: `/entities/${id}/medias`,
    });
  }

  if (
    ((organization && organization.features.commitments) ||
      currentUserOrganization) &&
    !organizationIsNetwork
  ) {
    items.push({
      icon: 'calendar',
      name: __('Calendar'),
      to: `/entities/${id}/commitments`,
    });
    mobileItems.push({
      icon: 'calendar',
      name: __('Calendar'),
      to: `/entities/${id}/commitments`,
    });
  }

  // using && eid !== null stage 2.
  if (
    type == 'STUDENT' &&
    organization.features.api &&
    organization.links.totalCount > 0 &&
    !organizationIsNetwork
  ) {
    items.push({
      icon: 'box open full',
      name: __('Accesses'),
      to: `/entities/${id}/accesses`,
    });
    mobileItems.push({
      icon: 'box open full',
      name: __('Accesses'),
      to: `/entities/${id}/accesses`,
    });
  }

  return { items, mobileItems };
}
