import { GetEntitiesWhoHasMessagesScheduled } from '../../../../Data/Repository/Message/GetEntitiesWhoHasMessagesScheduled';

export async function checkEntitiesHasMessagesScheduled(input) {
    if (!input.organizationId || !input.entityIds || !input.entityIds?.length) {
      throw Error('Missing required input parameters');
    }

    try {
       return GetEntitiesWhoHasMessagesScheduled(input);
    } catch (e) {
      console.log('Error while trying to get scheduled messages');
    }
}
