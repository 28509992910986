import PubSub from 'pubsub-js';

export function clearAllSubscriptions() {
  const events = [
    'MICROFRONTEND_READY',
    'WALLE_SET_NOTIFICATIONS',
    'KATARA_MICROFRONTEND_DATA',
    'STORE_UPDATED',
    'UPDATE_HOST_DIRECT_MESSAGE_READ_STATUS',
    'SEND_FORWARD_MESSAGE_TO_PERSON',
    'REMOVE_SEARCH_ENTITY',
    'STORE_UPDATED',
  ];
  events.forEach((event) => {
    PubSub.unsubscribe(event);
  });
}
