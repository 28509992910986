import gql from 'graphql-tag';
import { getChannelUnreadNotificationRepository } from '../GetChannelUnreadNotification';
import * as DataSource from '../../../DataSource/ApolloDataSource';

jest.mock('graphql-tag', () => jest.fn().mockReturnValue('query'));

describe('given the "getChannelUnreadNotificationRepository" function', () => {
  beforeEach(() => {
    jest.spyOn(DataSource, 'Query').mockImplementation(() => ({
      data: {
        node: {
          messages: {
            totalCount: 10,
          },
        },
      },
      error: null,
    }));
  });

  const query = [
    `
    query WalleGetEntityMenuCounter($entityId: ID!) {
      node(id: $entityId) {
        ... on Entity {
          messages(folder: UNREAD) {
            totalCount
          }
          moments(folder: NEW) {
            totalCount
          }
          directUnreadTotalCount
        }
      }
    }
  `,
  ];

  const variables = {
    entityId: 1,
  };

  it('should call the "Mutation" function with the correct params', async () => {
    await getChannelUnreadNotificationRepository(variables);

    expect(DataSource.Query).toHaveBeenCalledWith('query', variables);
  });

  it('should return Mutation response', async () => {
    const response = await getChannelUnreadNotificationRepository(variables);

    expect(response).toEqual({
      data: { node: { messages: { totalCount: 10 } } },
      error: null,
    });
  });

  it('should call the "gql" function with the correct mutation', async () => {
    await getChannelUnreadNotificationRepository(variables);

    expect(gql).toHaveBeenCalledWith(query);
  });
});
