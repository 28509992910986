import { renderHook, act } from '@testing-library/react-hooks/dom';
import { ViewModel as useViewModel } from '../ViewModel';
describe('Chats ViewModel', () => {
  it('should be able to set the mfcReady state', () => {
    const { result } = renderHook(() => useViewModel());

    act(() => {
      result.current.setMfcReady('KATARA');
    });

    expect(result.current.mfcReady).toBe('KATARA');
  });

  it('should be able to see the mfcReady as null by default', () => {
    const { result } = renderHook(() => useViewModel());

    expect(result.current.mfcReady).toBe(null);
  });
});
