import gql from 'graphql-tag';
import { Query } from '../../../DataSource/ApolloDataSource';
export const GetScheduledEntityMessages = gql`
  query GetScheduledEntityMessages($organizationId: ID!, $entityIds: [ID!]) {
    scheduledMessage(
        organizationId: $organizationId,
        entityIds: $entityIds
    ) {
      hasScheduledMessage
      entitiesWithScheduledMessage
    }
  }
`;


export async function GetEntitiesWhoHasMessagesScheduled(input) {
    const { data, error } = await Query(
      GetScheduledEntityMessages,
      {
        organizationId: input.organizationId,
        entityIds: input.entityIds,
      },
    );

    if (error) {
      console.log(error);
      return null;
    }
    
    return data.scheduledMessage;
}
