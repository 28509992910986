import React from 'react';
import PubSub from 'pubsub-js';

import { render, screen } from '../../../../../../newRender';
import ViewUI from '../ViewUI';
import { __ } from '../../../../../../i18n';
import * as useViewModel from '../ViewModel';
import * as analytics from '../../../../../../lib/analytics';
import * as store from '../../../../../../store';

const handleUpdateChatsReadStatus = jest.fn();

describe('Chats ViewUI', () => {
  let props = {
    router: {
      push: jest.fn(),
    },
  };

  beforeEach(() => {
    jest.resetAllMocks();
    jest.spyOn(PubSub, 'subscribe').mockImplementation((event, callback) => {
      callback();
    });
    jest
      .spyOn(analytics, 'sendGoogleAnalyticsEvent')
      .mockImplementation(() => {});
    jest
      .spyOn(store, 'createStore')
      .mockImplementation(() => ({ entity: { type: 'student' } }));

    jest.spyOn(useViewModel, 'ViewModel').mockReturnValue({
      handleUpdateChatsReadStatus,
      mfcReady: 'KATARA',
      setMfcReady: jest.fn(),
    });
  });

  it('should render header title', () => {
    render(<ViewUI {...props} />);
    const title = screen.getByText(__('Chats'));
    expect(title).toBeVisible();
  });

  it('should call analytics.sendGoogleAnalyticsEvent', () => {
    render(<ViewUI {...props} />);
    expect(analytics.sendGoogleAnalyticsEvent).toHaveBeenCalled();
  });

  it('should subscribe to SEND_FORWARD_MESSAGE_TO_PERSON', () => {
    render(<ViewUI {...props} />);
    expect(PubSub.subscribe).toHaveBeenCalledWith(
      'SEND_FORWARD_MESSAGE_TO_PERSON',
      expect.any(Function),
    );
  });

  it('should be able to publish event if currentEntity changes', () => {
    jest.clearAllMocks();
    jest.spyOn(PubSub, 'publish').mockImplementation(() => {});
    jest
      .spyOn(store, 'createStore')
      .mockImplementation(() => ({ entity: { type: 'student', id: 2 } }));
    render(<ViewUI {...props} />);

    expect(PubSub.publish).toHaveBeenCalledWith(
      'undefined_MICROFRONTEND_DATA',
      {
        entity: { type: 'student', id: 2 },
        host: undefined,
        organization: undefined,
        user: undefined,
        entityList: undefined,
      },
    );
  });

  it('should filter the current user entities correctly', () => {
    const currentUserMock = {
      entities: [
        {
          id: 1,
          type: 'SCHOOL',
          isChannel: false,
        },
        {
          id: 2,
          type: 'ADMIN',
          isChannel: true,
        },
        {
          id: 3,
          type: 'STAFF',
          isChannel: false,
        },
      ],
    };
    jest.spyOn(PubSub, 'publish').mockImplementation(() => {});
    jest.spyOn(store, 'createStore').mockImplementation(() => ({
      entity: { type: 'student', id: 2 },
      currentUser: currentUserMock,
    }));

    render(<ViewUI {...props} />);

    expect(PubSub.publish).toHaveBeenCalledWith(
      'undefined_MICROFRONTEND_DATA',
      {
        entity: { type: 'student', id: 2 },
        host: undefined,
        organization: undefined,
        user: currentUserMock,
        entityList: [currentUserMock.entities[1]],
      },
    );
  });
});
