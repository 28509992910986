import { checkEntitiesHasMessagesScheduled } from '../app/Domain/UseCases/Message/CheckEntitiesHasMessagesScheduled';

/*
  Description: 
    This function return name of entities with message scheduled. 
    For this we need receive tha mobx store and entities array of object with id and fullname of each entity selected
*/ 
export async function handleCheckEntitiesHasMessagesScheduled(entities, store){
  try {
    const { entitiesWithScheduledMessage } = await checkEntitiesHasMessagesScheduled({
      organizationId: store?.currentOrganization?.id,
      entityIds: entities?.map(e => e.id) || [],
    });
    
    return entities.filter(e => entitiesWithScheduledMessage.map(i => String(i)).includes(String(e.id))).map(e => e.fullname);
  } catch (error) {
    console.log(error);
    return [];
  }
}