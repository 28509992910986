import PubSub from 'pubsub-js';
import { clearAllSubscriptions } from '../clearAllSubscriptions';

describe('given the clearAllSubscriptions function', () => {
  const events = [
    'MICROFRONTEND_READY',
    'WALLE_SET_NOTIFICATIONS',
    'KATARA_MICROFRONTEND_DATA',
    'STORE_UPDATED',
    'UPDATE_HOST_DIRECT_MESSAGE_READ_STATUS',
    'SEND_FORWARD_MESSAGE_TO_PERSON',
    'REMOVE_SEARCH_ENTITY',
    'STORE_UPDATED',
  ];

  beforeEach(() => {
    jest.spyOn(PubSub, 'unsubscribe');
  });

  it('should call PubSub.unsubscribe with the expected events', () => {
    clearAllSubscriptions();
    events.forEach((event) => {
      expect(PubSub.unsubscribe).toHaveBeenCalledWith(event);
    });
  });
});
