import * as apolloDataSource from '../../../../DataSource/ApolloDataSource';
import { GetEntitiesWhoHasMessagesScheduled, GetScheduledEntityMessages } from '../index';

jest.mock('graphql-tag', () => ({
  __esModule: true,
  default: jest.fn(),
}));

describe('GetEntitiesWhoHasMessagesScheduled', () => {
  it('should call Query with the correct params', async () => {
    const variables = {
      organizationId: '123',
      entityIds: ['1', '2'],
    };
    const queryMock = ({
      data: {
        scheduledMessage: {
          hasScheduledMessage: true,
          entitiesWithScheduledMessage: ['1', '2'],
        },
      },
    });
    jest.spyOn(apolloDataSource, 'Query').mockImplementation(() => queryMock);

    const res = await GetEntitiesWhoHasMessagesScheduled(variables);

    expect(apolloDataSource.Query).toHaveBeenCalledWith(GetScheduledEntityMessages, {
      ...variables
    });

    expect(res).toEqual({
      hasScheduledMessage: true,
      entitiesWithScheduledMessage: ['1', '2'],
    });
  });

  it('should call Query with the correct params and return error', async () => {
    const variables = {
      organizationId: '123',
      entityIds: ['1', '2'],
    };
    const queryMock = ({
      error: 'Error'
    });
    jest.spyOn(console, 'log').mockImplementation(() => {});

    jest.spyOn(apolloDataSource, 'Query').mockImplementation(() => queryMock);

    const res = await GetEntitiesWhoHasMessagesScheduled(variables);

    expect(apolloDataSource.Query).toHaveBeenCalledWith(GetScheduledEntityMessages, {
      ...variables
    });

    expect(console.log).toHaveBeenCalledWith('Error');
    expect(res).toEqual(null) ;
  });
});
