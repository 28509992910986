import { useState } from 'react';

export function ViewModel() {
  const [mfcReady, setMfcReady] = useState(null);

  return {
    mfcReady,
    setMfcReady,
  };
}
